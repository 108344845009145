<template>
  <router-link
    class="week-event"
    :to="'/events/' + event.id + '/' + event.slug + '/'"
    v-if="event.id"
  >
    <div class="week-event--image">
      <img :src="event.image" />
    </div>
    <div class="week-event--info">
      <div
        v-if="event.typology"
        class="week-event--info--icon"
        v-html="event.typology.icon"
      ></div>
      <h3
        class="week-event--info--title"
        v-if="this.$root.$i18n.locale === 'es'"
      >
        {{ event.title.es }}
      </h3>
      <h3
        class="week-event--info--title"
        v-if="this.$root.$i18n.locale === 'ca'"
      >
        {{ event.title.ca ? event.title.ca : event.title.es }}
      </h3>
      <h3
        class="week-event--info--title"
        v-if="this.$root.$i18n.locale === 'en'"
      >
        {{ event.title.en ? event.title.en : event.title.es }}
      </h3>
      <div class="week-event--info--collapse">
        <div
          class="week-event--info--collapse--location"
          v-if="this.$root.$i18n.locale === 'es'"
        >
          {{ event.location.es }}
        </div>
        <div
          class="week-event--info--collapse--location"
          v-if="this.$root.$i18n.locale === 'ca'"
        >
          {{ event.location.ca ? event.location.ca : event.location.es }}
        </div>
        <div
          class="week-event--info--collapse--location"
          v-if="this.$root.$i18n.locale === 'en'"
        >
          {{ event.location.en ? event.location.en : event.location.es }}
        </div>
        <div class="week-event--info--collapse--time">
          {{ event.start_time }} -
          {{ event.end_time }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.week-event {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;

  &:hover {
    .week-event--info {
      transform: translateX(0);
    }
    .week-event--info--collapse {
      opacity: 0.8;
      visibility: visible;
      transform: translateY(0);
    }
    .week-event--image {
      img {
        transform: translateX(0);
      }
    }
  }
  &--image {
    width: 9vw;
    min-width: 9vw;
    max-width: 9vw;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translateX(9vw);
      transition: all 0.3s ease;
    }
  }
  &--info {
    // background-color: #efefef;
    // transform: translateX(calc(-9vw + $base * 2));
    transform: translateX(0);
    transition: all 0.3s ease;
    z-index: 1;
    border-left: solid $base $body_color;
    padding: $base * 0.5 $base * 2;
    display: flex;
    flex-direction: column;
    gap: $base * 1;
    // max-width: 70%;
    &--title {
      font-size: $base * 2.625;
      line-height: $base * 3;
      font-weight: 400;
      font-family: $font_family_alt;
      text-transform: uppercase;
    }
    &--collapse {
      display: flex;
      flex-direction: column;
      opacity: 0;
      visibility: hidden;
      transform: translateY($base * 2);
      font-size: $base * 2.25;
      line-height: $base * 3;
      transition: all 0.3s ease;
    }
  }
}
</style>
