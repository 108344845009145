<template>
  <router-link
    :to="'/units/' + unit.id + '/' + unit.slug + '/'"
    class="unit-card--mobile"
    v-if="this.$vuetify.display.mobile"
  >
    <div class="unit-card--mobile__img">
      <img :src="unit.cover_image.src" :alt="unit.name" />
    </div>
    <div class="unit-card--mobile__gradient"></div>
    <div class="unit-card--mobile__inner">
      <div class="unit-card--mobile__inner__info">
        <div class="unit-card--mobile__inner__info__acronym">
          <h3>{{ unit.acronym }}</h3>
        </div>
        <div class="unit-card--mobile__inner__info__name">
          <h4>{{ unit.name }}</h4>
        </div>
      </div>
      <div class="unit-card--mobile__inner__button">
        <router-link :to="'/units/' + unit.id + '/' + unit.slug + '/'">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="black" fill-opacity="0.4" />
            <path
              d="M16.0846 26H18.6911L28 15.9837L18.6911 6H16.0846L24.3103 15.0407H4V16.9593H24.2764L16.0846 26Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </router-link>
  <router-link
    class="unit-card"
    v-else
    :to="'/units/' + unit.id + '/' + unit.slug + '/'"
  >
    <div class="unit-card__img">
      <img :src="unit.cover_image.src" :alt="unit.name" />
    </div>
    <div class="unit-card__gradient"></div>
    <div class="unit-card--content">
      <div class="unit-card--content__info">
        <div class="unit-card--content__info__acronym">
          <h3>{{ unit.acronym }}</h3>
        </div>
        <div class="unit-card--content__info__name">
          <h4>{{ unit.name }}</h4>
        </div>
      </div>
      <div class="unit-card--content__button">
        <router-link :to="'/units/' + unit.id + '/' + unit.slug + '/'">
          <svg
            width="30"
            height="54"
            viewBox="0 0 30 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 3L27 27L3 51"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  name: "UnitProgramCard",
  props: {
    unit: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
//Mobile
.unit-card--mobile {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: $base * 15;
  max-height: $base * 20;
  display: block;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba($body_color_rgb, 0.8) 13.54%,
      rgba($body_color_rgb, 0.0369231) 100%
    );
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: $base * 2;
    margin-bottom: $base * 5;
    &__info {
      color: $body_bg;
      max-width: 70.84548105%;
      &__acronym {
        h3 {
          font-size: $base * 5;
          line-height: $base * 5;
          font-weight: 400;
          font-family: $font_family_alt;
        }
      }
      &__name {
        h4 {
          font-size: $base * 2.5;
          line-height: $base * 3;
          font-weight: 400;
        }
      }
    }
    &__button {
      margin-top: auto;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.unit-card {
  width: 100%;
  position: relative;
  min-height: $base * 22;
  max-height: $base * 22;
  display: flex;
  cursor: pointer;
  &:hover {
    .unit-card__gradient {
      max-width: 100%;
    }
    .unit-card--content {
      .unit-card--content__button {
        opacity: 1;
        visibility: visible;
      }
      .unit-card--content__info__name {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: 38%;
    transition: max-width 0.3s ease-in-out;
    background: linear-gradient(
      90deg,
      rgba($body_color_rgb, 0.8) 0%,
      rgba($body_color_rgb, 0) 100%
    );
  }
  &--content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: $base * 4 $base * 6;
    gap: $base * 5;
    &__info {
      color: $body_bg;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: $base * 5;
      &__acronym {
        h3 {
          padding-top: $base * 2.2;
          font-size: $base * 12;
          line-height: $base * 12;
          font-weight: 400;
          font-family: $font_family_alt;
        }
      }
      &__name {
        max-width: 38%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        h4 {
          font-size: $base * 4.75;
          line-height: $base * 5;
          font-weight: 400;
        }
      }
    }
    &__button {
      opacity: 0;
      visibility: hidden;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
