<template>
  <div class="page-content page-unit-single--content">
    <breadcrumbs-component
      :breadcrumbs="breadcrumbs"
      v-if="unit"
    ></breadcrumbs-component>
    <header class="unit-single--header">
      <div class="unit-single--header--title" v-if="unit">
        <h1 class="unit-single--header--title__acronym" v-if="!this.loading">
          {{ unit.acronym }}
        </h1>
        <h2
          class="unit-single--header--title__name"
          v-if="this.$root.$i18n.locale === 'es' && !this.loading"
        >
          {{ unit.name.es }}
        </h2>
        <h2
          class="unit-single--header--title__name"
          v-if="this.$root.$i18n.locale === 'ca'"
        >
          {{ unit.name.ca ? unit.name.ca : unit.name.es }}
        </h2>
        <h2
          class="unit-single--header--title__name"
          v-if="this.$root.$i18n.locale === 'en'"
        >
          {{ unit.name.en ? unit.name.en : unit.name.es }}
        </h2>
      </div>
      <div class="unit-single--header--actions" v-if="unit">
        <a :href="unit.socials.instagram" target="_blank">
          <v-icon color="#060606">mdi-instagram</v-icon>
        </a>
        <a :href="unit.socials.externalLink" target="_blank">
          <v-icon color="#060606">mdi-web</v-icon>
        </a>
        <a :href="unit.socials.externalLink" target="_blank">
          <v-icon color="#060606">mdi-email-outline</v-icon>
        </a>
      </div>
    </header>
    <div class="unit-single--content" v-if="unit">
      <div class="unit-single--content--left">
        <div
          class="unit-single--content--left__text"
          v-html="unit.content.leftCol.es"
          v-if="this.$root.$i18n.locale === 'es'"
        ></div>
        <div
          class="unit-single--content--left__text"
          v-html="
            unit.content.leftCol.ca
              ? unit.content.leftCol.ca
              : unit.content.leftCol.es
          "
          v-if="this.$root.$i18n.locale === 'ca'"
        ></div>
        <div
          class="unit-single--content--left__text"
          v-html="
            unit.content.leftCol.en
              ? unit.content.leftCol.en
              : unit.content.leftCol.es
          "
          v-if="this.$root.$i18n.locale === 'en'"
        ></div>
      </div>
      <div class="unit-single--content--right">
        <div class="unit-single--content--right--image">
          <div class="unit-single--content--right--image__img">
            <img :src="unit.contentImage.large" />
          </div>
          <div
            class="unit-single--content--right--image__caption"
            v-if="this.$root.$i18n.locale === 'es'"
          >
            {{ unit.contentImage.caption.es }}
          </div>
          <div
            class="unit-single--content--right--image__caption"
            v-if="this.$root.$i18n.locale === 'ca'"
          >
            {{
              unit.contentImage.caption.ca
                ? unit.contentImage.caption.ca
                : unit.contentImage.caption.es
            }}
          </div>
          <div
            class="unit-single--content--right--image__caption"
            v-if="this.$root.$i18n.locale === 'en'"
          >
            {{
              unit.contentImage.caption.en
                ? unit.contentImage.caption.en
                : unit.contentImage.caption.es
            }}
          </div>
        </div>
        <div
          class="unit-single--content--right__text"
          v-html="unit.content.rightCol.es"
          v-if="this.$root.$i18n.locale === 'es'"
        ></div>
        <div
          class="unit-single--content--right__text"
          v-html="
            unit.content.rightCol.ca
              ? unit.content.rightCol.ca
              : unit.content.rightCol.es
          "
          v-if="this.$root.$i18n.locale === 'ca'"
        ></div>
        <div
          class="unit-single--content--right__text"
          v-html="
            unit.content.rightCol.en
              ? unit.content.rightCol.en
              : unit.content.rightCol.es
          "
          v-if="this.$root.$i18n.locale === 'en'"
        ></div>
      </div>
    </div>
    <div class="unit-single--bottom">
      <div class="unit-single--bottom--related-posts">
        <h3 class="unit-single--bottom--related-posts__title">
          {{ $t("related_posts") }}
        </h3>
        <div
          class="unit-single--bottom--related-posts__swiper"
          v-if="this.unit && this.unit.projectSlider"
        >
          <related-post-slider
            :slides="unit.projectSlider"
          ></related-post-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import RelatedPostSlider from "@/components/sliders/RelatedPostSlider.vue";
import BreadcrumbsComponent from "@/components/layout/BreadcrumbsComponent.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      unit: null,
      loading: false,
    };
  },
  components: {
    RelatedPostSlider,
    BreadcrumbsComponent,
  },
  async created() {
    await this.fetchUnit();
    if (this.unit) {
      console.log(this.unit);
      if (this.$root.$i18n.locale === "es") {
        document.title =
          "DPA UPV | " + this.unit.acronym + " - " + this.unit.name.es;
      } else if (this.$root.$i18n.locale === "ca") {
        document.title =
          "DPA UPV | " + this.unit.acronym + " - " + this.unit.name.ca;
      } else if (this.$root.$i18n.locale === "en") {
        document.title =
          "DPA UPV | " + this.unit.acronym + " - " + this.unit.name.en;
      }
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: {
            es: this.$t("programs"),
            en: this.$t("programs"),
            ca: this.$t("programs"),
          },
          to: "/programs",
        },
        {
          text: {
            es: this.$t("units"),
            en: this.$t("units"),
            ca: this.$t("units"),
          },
          to: "/units",
        },
        {
          text: {
            es: this.unit.name.es
              ? this.unit.acronym + " - " + this.unit.name.es
              : "",
            en: this.unit.name.en
              ? this.unit.acronym + " - " + this.unit.name.en
              : "",
            ca: this.unit.name.ca
              ? this.unit.acronym + " - " + this.unit.name.ca
              : "",
          },
          to: "/units/" + this.unit.id + "/" + this.unit.slug,
        },
      ];
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),

    async fetchUnit() {
      this.startLoading(); // Inicia el estado de carga antes de la llamada a la API

      // Reemplaza con el ID de la unidad que quieras cargar
      this.unit = await PostsService.getUnitById(this.$route.params.id)
        .then((unitData) => {
          return {
            id: unitData.id,
            slug: unitData.slug,
            acronym: unitData.title.rendered,
            name: {
              es: unitData.acf.unit_name.name_es,
              en: unitData.acf.unit_name.name_en,
              ca: unitData.acf.unit_name.name_cat,
            },
            coverImage: {
              large: unitData.acf.cover_image.sizes.large,
              full: unitData.acf.cover_image.url,
            },
            description: {
              es: unitData.acf.unit_description.description_es,
              en: unitData.acf.unit_description.description_en,
              ca: unitData.acf.unit_description.description_cat,
            },
            contentImage: {
              large: unitData.acf.unit_content.content_image.image
                ? unitData.acf.unit_content.content_image.image.sizes.large
                : null,
              full: unitData.acf.unit_content.content_image.image.url,
              caption: {
                es: unitData.acf.unit_content.content_image.caption.caption_es,
                en: unitData.acf.unit_content.content_image.caption.caption_en,
                ca: unitData.acf.unit_content.content_image.caption.caption_cat,
              },
            },
            content: {
              leftCol: {
                es: unitData.acf.unit_content.left_column.content_es,
                en: unitData.acf.unit_content.left_column.content_en,
                ca: unitData.acf.unit_content.left_column.content_cat,
              },
              rightCol: {
                es: unitData.acf.unit_content.right_column.content_es,
                en: unitData.acf.unit_content.right_column.content_en,
                ca: unitData.acf.unit_content.right_column.content_cat,
              },
            },
            projectSlider: unitData.acf.unit_content.project_slider,
            socials: {
              instagram: unitData.acf.unit_socials.instagram,
              externalLink: unitData.acf.unit_socials.external_link,
              mail: unitData.acf.unit_socials.mail,
            },
            // title: unitData.title.rendered,
            // content: unitData.content.rendered,
            // image: unitData.acf.imagen,
            // Agrega más campos si los necesitas
          };
        })
        .finally(() => {
          this.isLoading = false;
          this.stopLoading(); // Finaliza el estado de carga después de recibir la respuesta o en caso de error
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-unit-single--content {
  padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;
  margin-bottom: $base * 4;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    column-gap: $base * 3;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .unit-single--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: $base * 2;
    margin-bottom: $base * 7;

    @media (min-width: 1024px) {
      grid-column: 2 / span 10;
      align-items: flex-end;
      margin-bottom: $base * 6;
      margin-top: $base * 4;
    }

    &--title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: $font_family_alt;
      text-transform: uppercase;

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-end;
        gap: $base * 3;
      }

      &__acronym {
        font-size: $base * 6;
        line-height: $base * 5.5;
        margin: 0;
        font-weight: 400;
        margin-bottom: $base * 1.5;

        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }

      &__name {
        font-size: $base * 4;
        line-height: $base * 4.5;
        font-weight: 400;
      }
    }

    &--actions {
      display: flex;
      flex-direction: column;
      gap: $base * 2;

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }

  .unit-single--content {
    display: flex;
    flex-direction: column;
    gap: $base * 4;

    @media (min-width: 1024px) {
      grid-column: 2 / span 10;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $base * 3;
    }

    &--left {
      display: flex;
      flex-direction: column;
      gap: $base * 4;

      @media (min-width: 1024px) {
        grid-column: 1 / span 1;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      gap: $base * 4;

      @media (min-width: 1024px) {
        grid-column: 2 / span 1;
      }

      &--image {
        width: 100%;

        &__img {
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        &__caption {
          font-size: $base * 2;
          line-height: $base * 2.5;
          font-weight: 400;
        }
      }
    }
  }

  .unit-single--bottom {
    margin-top: $base * 7;
    margin-bottom: $base * 7;

    @media (min-width: 1024px) {
      grid-column: 2 / span 10;
    }

    &--related-posts {
      width: 100%;

      &__title {
        font-size: $base * 3;
        line-height: $base * 3;
        font-family: $font_family_alt;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: $base * 2;

        @media (min-width: 1024px) {
          font-size: $base * 4.5;
          line-height: $base * 5;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.unit-single--content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font_family_alt;
    text-transform: uppercase;
    font-weight: 400;
  }
}
</style>
