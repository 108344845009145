<template>
  <div class="breadcrumbs">
    <div class="breadcrumbs--inner" v-if="!hideBreadcrumbs">
      <template v-for="(crumb, index) in breadcrumbs" :key="index">
        <template v-if="index !== breadcrumbs.length - 1">
          <router-link :to="crumb.to" v-if="this.$root.$i18n.locale === 'es'">{{
            crumb.text.es
          }}</router-link>
          <router-link :to="crumb.to" v-if="this.$root.$i18n.locale === 'ca'">{{
            crumb.text.ca ? crumb.text.ca : crumb.text.es
          }}</router-link>
          <router-link :to="crumb.to" v-if="this.$root.$i18n.locale === 'en'">{{
            crumb.text.en ? crumb.text.en : crumb.text.es
          }}</router-link>
          <span class="breadcrumb-arrow">→</span>
        </template>
        <span v-else>
          <template v-if="this.$root.$i18n.locale === 'es'">{{
            crumb.text.es
          }}</template>
          <template v-if="this.$root.$i18n.locale === 'ca'">{{
            crumb.text.ca ? crumb.text.ca : crumb.text.es
          }}</template>
          <template v-if="this.$root.$i18n.locale === 'en'">{{
            crumb.text.en ? crumb.text.en : crumb.text.es
          }}</template></span
        >
      </template>
    </div>
    <div
      class="back-button"
      v-if="!hideBackButton"
      :class="hideBreadcrumbs ? 'no-breadcrumbs' : ''"
    >
      <button @click="goBack()"><span>←</span>{{ $t("back") }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => {},
    },
    hideBreadcrumbs: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("popstate", this.goBack);
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
  },
  beforeUnmount() {
    window.removeEventListener("popstate", this.goBack);
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  flex-direction: column-reverse;
  color: rgba($body_color_rgb, 0.7);
  text-transform: capitalize;
  @media (min-width: 1024px) {
    grid-column: 2 / span 10;
    flex-direction: column;
  }
  &--inner {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $base * 2;
    gap: $base;
    font-size: $base * 1.75;
    line-height: $base * 1.75;
    @media (min-width: 1024px) {
      margin-bottom: $base * 3.75;
      font-size: $base * 2.625;
      line-height: $base * 4;
    }
    a {
      color: $corporate_color;
    }
  }
  .back-button {
    margin-bottom: $base * 4.125;
    margin-left: -$base * 2;
    @media (min-width: 1024px) {
      margin-bottom: $base * 3;
      margin-left: 0;
      &.no-breadcrumbs {
        margin-top: $base * 3.75;
      }
    }
    button {
      font-size: $base * 2;
      line-height: $base * 2;
      font-family: $font_family_alt;
      text-transform: uppercase;
      @media (min-width: 1024px) {
        font-size: $base * 2.625;
        line-height: $base * 2.625;
      }

      span {
        font-size: $base * 2;
        margin-right: $base * 0.5;
        @media (min-width: 1024px) {
          font-size: $base * 3;
        }
      }
    }
  }
}
</style>
