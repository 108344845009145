<template>
  <footer class="footer-mobile">
    <div class="footer-mobile--inner">
      <div class="footer-mobile--inner--first-row">
        <div class="footer-mobile--inner--first-row--links">
          <a
            href="https://archivedpa.webs.upv.es/"
            target="_blank"
            class="footer-mobile--inner--first-row--links__item"
            >{{ $t("archive") }}</a
          >
          <a
            href="https://www.instagram.com/dpa.upv/"
            target="_blank"
            class="footer-mobile--inner--first-row--links__item"
            >Instagram</a
          >
        </div>
        <div class="footer-mobile--inner--first-row--logos">
          <a
            href="http://www.upv.es/entidades/ETSA/"
            target="_blank"
            rel="noopener noreferrer"
            class="footer-mobile--inner--first-row--logos__item"
          >
            <logo-etsa-svg />
          </a>
          <a
            href="https://www.upv.es"
            target="_blank"
            rel="noopener noreferrer"
            class="footer-mobile--inner--first-row--logos__item"
          >
            <logo-upv-svg />
          </a>
        </div>
      </div>
      <div class="footer-mobile--inner--second-row">
        {{ $t("footer_text") }}
      </div>
      <div class="footer-mobile--inner--third-row">
        <div class="footer-mobile--inner--third-row__copyright">
          Proudly baked by
          <a href="https://contra-architecture.com/" target="_blank">CONTRA</a>
          & <a href="https://mdtec.es/" target="_blank">MDTEC</a>
        </div>
        <div class="footer-mobile--inner--third-row__department">
          <a href="https://dparq.upv.es/" target="_blank">{{
            $t("department")
          }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoUpvSvg from "@/components/svgs/LogoUpvSvg.vue";
import LogoEtsaSvg from "@/components/svgs/LogoEtsaSvg.vue";

export default {
  name: "FooterMobile",
  components: {
    LogoUpvSvg,
    LogoEtsaSvg,
  },
};
</script>

<style lang="scss" scoped>
.footer-mobile {
  background: #060606;
  width: 100%;
  &--inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: $base * 2;
    padding: $base * 3 $base * 2;
    &--first-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: $base * 2;
      &--links {
        display: flex;
        flex-direction: column;
        gap: $base * 0.5;
        &__item {
          font-size: $base * 1.75;
          line-height: $base * 2;
          font-weight: 800;
          color: #f6f6f6 !important;
          text-decoration: none;
          text-transform: uppercase;
          display: flex;
          margin: 0;
        }
      }
      &--logos {
        display: flex;
        flex-wrap: nowrap;
        gap: $base * 2;
        width: 66%;
        &__item {
          width: 45%;
        }
      }
    }
    &--second-row {
      font-size: $base * 1.375;
      line-height: $base * 2;
      color: #f6f6f6;
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $base * 2;
      text-align: center;
    }
    &--third-row {
      color: #f6f6f6;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      a {
        color: #f6f6f6;
        text-decoration: none;
      }
    }
  }
}
</style>
<style lang="scss">
.footer-mobile--inner--first-row--logos__item {
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #f6f6f6;
    }
  }
}
</style>
