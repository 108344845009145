<template>
  <div class="hero-home-swiper">
    <swiper
      :effect="'coverflow'"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      :space-between="0"
      grabCursor
      :pagination="{ clickable: true }"
      :coverflowEffect="{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }"
      :modules="modules"
    >
      <swiper-slide v-for="slide in slides" :key="slide.link">
        <!-- Render original HTML in server, render Swiper in browser (client) -->
        <div class="swiper-slide__img">
          <img :src="slide.image" />
        </div>
        <div class="swiper-slide__gradient" />
        <div class="swiper-slide__copy">
          <a :href="slide.link" class="swiper-slide__copy__link">
            <div
              class="swiper-slide__copy__text"
              v-if="this.$root.$i18n.locale === 'es'"
            >
              {{ slide.text.es }}
            </div>
            <div
              class="swiper-slide__copy__text"
              v-if="this.$root.$i18n.locale === 'en'"
            >
              {{ slide.text.en ? slide.text.en : slide.text.es }}
            </div>
            <div
              class="swiper-slide__copy__text"
              v-if="this.$root.$i18n.locale === 'ca'"
            >
              {{ slide.text.ca ? slide.text.ca : slide.text.es }}
            </div>
          </a>
        </div>
      </swiper-slide>
      <div class="swiper-upv-logo"><span class="ultrabold">DPA</span>UPV</div>
    </swiper>
  </div>
</template>
<script>
// import Swiper core and required modules
import { Pagination, EffectCoverflow } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [EffectCoverflow, Pagination],
    };
  },
};
</script>
<style lang="scss" scoped>
.hero-home-swiper {
  height: 100%;
  .swiper {
    background: $body_color;
    width: 100%;
    height: 100%;
    // height: calc(
    //   var(--app-height) - var(--app-height) * 0.09977800407 - #{$base * 1}
    // );
    // min-height: calc(var(--app-height) - $base * 6);
    // max-height: calc(var(--app-height) - $base * 11);
    // height: clamp(
    //   calc(var(--app-height) - $base * 6),
    //   calc(var(--app-height) - var(--app-height) * 0.09877800407),
    //   calc(var(--app-height) - $base * 11)
    // );
    position: relative;

    //   height: calc(var(--app-height) * 0.9012219959);
    @media (min-width: 1024px) {
      // height: calc(
      //   (var(--app-height) - (var(--app-height) * 0.09977800407)) - #{$base} * 40
      // );
      // min-height: calc(var(--app-height) - $base * 6);
      // max-height: calc(var(--app-height) - $base * 11);
    }

    .swiper-wrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        position: relative;
        width: 100%;
        height: 100%;

        // background: orange;
        &__img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: -1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        &__copy {
          position: absolute;
          top: $base * 4.125;
          left: $base * 3.5;
          min-width: 47.02380952vw;
          max-width: 50vw;
          font-size: $base * 3;
          line-height: $base * 3.5;
          //   line-height: $base * 10;
          font-family: $font_family_alt;
          text-transform: uppercase;
          z-index: 10;

          a {
            color: $body_bg;
            text-decoration: none;
            // &:hover{
            //   text-decoration: underline;
            // }
          }

          @media (min-width: 1024px) {
            top: auto;
            bottom: calc(var(--app-height) * 0.06211812627);
            left: 8.134920635vw;
            font-size: $base * 10;
            line-height: $base * 10.5;
          }
        }

        &__gradient {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba($body_color_rgb, 0) 44.75%,
            rgba($body_color_rgb, 1) 100%
          );
          z-index: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          pointer-events: none;
        }
      }
    }

    .swiper-upv-logo {
      position: absolute;
      z-index: 10;
      bottom: $base * 6.125;
      right: $base * 3.5;
      margin-top: auto;
      display: flex;
      color: $body_bg;
      align-items: center;
      font-size: $base * 4;
      line-height: $base * 4.5;
      font-weight: normal;
      font-family: $font_family_alt;

      //   mix-blend-mode: difference;
      @media (min-width: 1024px) {
        bottom: calc(var(--app-height) * 0.06211812627);
        right: 8.134920635vw;
        font-size: $base * 10;
        line-height: $base * 10.5;
      }

      span.ultrabold {
        font-weight: 900;
      }
    }
  }
}
</style>
<style lang="scss">
.hero-home-swiper {
  .swiper-pagination-bullets {
    z-index: 10;
    bottom: $base * 2.25;

    @media (min-width: 1024px) {
      bottom: calc(var(--app-height) * 0.06211812627 - #{$base * 2});
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      background: transparent;
      border: solid 2px $body_bg;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: $body_bg;
      }
    }
  }
}
</style>
