<template>
  <div class="burger-menu" :class="isToggle ? 'open' : ''">
    <div class="burger-menu__inner">
      <div class="burger-menu--toggle" @click="toggleNav()">
        <div
          class="burger-menu--toggle__inner"
          :class="
            isToggle
              ? 'burger-menu--toggle__inner__active'
              : 'burger-menu--toggle__inner__inactive'
          "
        >
          <div class="burger-menu--toggle__inner__line" />
          <div class="burger-menu--toggle__inner__line" />
          <div class="burger-menu--toggle__inner__line" />
        </div>
      </div>
      <div
        class="burger-menu--content"
        :class="
          isToggle
            ? 'burger-menu--content__active'
            : 'burger-menu--content__inactive'
        "
      >
        <div class="burger-menu--content__inner">
          <div class="burger-menu--content__inner__logos">
            <a
              href="https://www.upv.es"
              target="_blank"
              rel="noopener noreferrer"
              class="burger-menu--content__inner__logos__item"
              @click="toggleNav"
            >
              <logo-upv-svg />
            </a>
            <a
              href="http://www.upv.es/entidades/ETSA/"
              target="_blank"
              rel="noopener noreferrer"
              class="burger-menu--content__inner__logos__item"
              @click="toggleNav"
            >
              <logo-etsa-svg />
            </a>
          </div>
          <lang-selector @click="toggleNav" />
          <div class="burger-menu--content__inner__links">
            <div class="links--group">
              <router-link to="/people" class="menu--item" @click="toggleNav">
                {{ $t("people") }}
              </router-link>
              <ul class="submenu--items">
                <li>
                  <router-link
                    @click="toggleNav"
                    :to="{
                      name: 'People',
                      query: {
                        taxonomyId: 26,
                      },
                    }"
                  >
                    {{ $t("staff") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="toggleNav"
                    :to="{
                      name: 'People',
                      query: {
                        taxonomyId: 24,
                      },
                    }"
                  >
                    {{ $t("students") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="toggleNav"
                    :to="{
                      name: 'People',
                      query: {
                        taxonomyId: 25,
                      },
                    }"
                  >
                    {{ $t("faculty") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="links--group">
              <router-link to="/events" class="menu--item" @click="toggleNav">
                {{ $t("daily") }}
              </router-link>
              <ul class="submenu--items">
                <li>
                  <router-link to="/events/search" @click="toggleNav">
                    {{ $t("calendar.search") }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/events?view=weekly" @click="toggleNav">
                    {{ $t("calendar.week") }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/events?view=monthly" @click="toggleNav">
                    {{ $t("calendar.month") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="links--group">
              <router-link to="/programs" class="menu--item" @click="toggleNav">
                {{ $t("programs") }}
              </router-link>
              <ul class="submenu--items">
                <li>
                  <router-link to="/programs" @click="goToProgram(0)">
                    {{ $t("bachelor") }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/programs" @click="goToProgram(1)">
                    {{ $t("master") }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/programs" @click="goToProgram(2)">
                    {{ $t("research") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <!-- <div class="links--group">
              <router-link to="/units" class="menu--item" @click="toggleNav">
                {{ $t("units") }}
              </router-link>
            </div> -->
            <div class="links--group">
              <router-link
                to="/structure"
                class="menu--item"
                @click="toggleNav"
              >
                {{ $t("structure") }}
              </router-link>
            </div>
          </div>
          <div class="burger-menu--content__inner__footer">
            <div class="burger-menu--content__inner__footer__instagram">
              <a
                href="https://www.instagram.com/etsa.upv/"
                target="_blank"
                rel="noopener noreferrer"
                @click="toggleNav"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6663 6.66675H13.3329C9.65103 6.66675 6.66626 9.65152 6.66626 13.3334V26.6667C6.66626 30.3486 9.65103 33.3334 13.3329 33.3334H26.6663C30.3482 33.3334 33.3329 30.3486 33.3329 26.6667V13.3334C33.3329 9.65152 30.3482 6.66675 26.6663 6.66675Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.9995 25C22.7609 25 24.9995 22.7614 24.9995 20C24.9995 17.2386 22.7609 15 19.9995 15C17.2381 15 14.9995 17.2386 14.9995 20C14.9995 22.7614 17.2381 25 19.9995 25Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.4995 12.5V12.5013"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div
              v-if="!this.$vuetify.display.mobile"
              class="burger-menu--content__inner__footer__disclaimer"
            >
              {{ $t("structure_link_text") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LangSelector from "@/components/LangSelector.vue";
import LogoUpvSvg from "@/components/svgs/LogoUpvSvg.vue";
import LogoEtsaSvg from "@/components/svgs/LogoEtsaSvg.vue";
export default {
  name: "BurgerMenu",
  components: { LangSelector, LogoUpvSvg, LogoEtsaSvg },
  data() {
    return {
      isToggle: false,
    };
  },
  methods: {
    toggleNav() {
      this.isToggle = !this.isToggle;
      // update store menuOpen state
      // this.$store.commit('menuOpen', this.isToggle)

      /* Prevent scroll when menu is open */
      // if(this.isToggle){
      //     document.documentElement.style.overflow = 'hidden'
      // }
      // else{
      //     document.documentElement.style.overflow = 'auto'
      // }
    },
    goToProgram(index) {
      this.isToggle = !this.isToggle;
      this.$store.dispatch("setActiveSlideIndex", index);
      document.body.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: var(--app-height);
  max-width: 0;
  z-index: 100;
  transition: max-width 0.5s ease 0.2s;

  &.open {
    // z-index: 100;
    transition: max-width 0.5s ease 0s;
    max-width: 100%;
  }

  &__inner {
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.burger-menu--toggle {
  cursor: pointer;
  position: fixed;
  right: 0;
  top: calc(var(--app-height) / 2);
  transform: translate(0, -50%);
  width: 100%;
  height: 100%;
  max-width: $base * 8;
  max-height: $base * 8;
  overflow: hidden;
  background-color: rgba($body_color_rgb, 0.8);
  z-index: 100;

  &:hover {
    & .burger-menu--toggle__inner {
      transform: rotate(90deg);
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: $base;
    background-color: transparent;
    padding: $base * 1.5;
    transition: transform 0.2s ease;

    &__line {
      width: $base * 5;

      height: $base * 0.5;
      background-color: rgba($body_bg_rgb, 1);
      transition: all 0.3s ease;
    }

    &__active {
      .burger-menu--toggle__inner__line:nth-child(1) {
        position: absolute;
        top: $base * 3.8;
        transform: rotate(45deg);
        transform-origin: center;
        background-color: rgba($body_bg_rgb, 1);
      }

      .burger-menu--toggle__inner__line:nth-child(3) {
        position: absolute;
        top: $base * 3.8;
        transform: rotate(-45deg);
        transform-origin: center;
        background-color: rgba($body_bg_rgb, 1);
      }

      // change center line opacity to 0
      .burger-menu--toggle__inner__line:nth-child(2) {
        position: absolute;
        transform: translateX(100%);
        opacity: 0;
        transition: none;
        background-color: rgba($body_bg_rgb, 1);
      }
    }
  }
}

.burger-menu--content {
  width: 30vw;
  min-width: 250px;
  height: 100%;
  color: rgb($body_bg_rgb);
  background-color: rgba($body_color_rgb, 0.95);
  transition: all 0.5s ease;

  &__active {
    transform: translateX(0);
  }

  &__inactive {
    transform: translateX(100%);
  }

  &__inner {
    padding: $base * 2 $base * 4;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: $base * 2;
    padding-top: $base * 2;

    &__top-bar {
      display: flex;
      width: calc(100% + #{$base * 4});
      margin-left: -$base * 2;
      flex-direction: row;
      justify-content: space-between;
    }

    &__logos {
      display: flex;
      flex-wrap: nowrap;
      gap: $base * 6;
      width: 100%;
      margin-left: -$base;
      padding: 0;

      &__item {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
          height: auto;
          object-fit: cover;

          g {
            path {
              fill: $body_bg;
              transition: all 0.3s ease;
            }
          }

          path {
            fill: $body_bg;
            transition: all 0.3s ease;
          }
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $base * 4;

      a {
        color: $body_bg;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .links--group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: $base;

        .menu--item {
          font-size: $base * 3;
          font-family: $font_family_base;
          //   letter-spacing: -0.02em;
          line-height: 1;
          font-weight: 800;
          text-transform: uppercase;
        }

        .submenu--items {
          display: flex;
          flex-direction: column;
          text-align: left;
          gap: $base * 0.5;

          li a {
            font-size: $base * 2;
            font-family: $font_family_alt;
            line-height: 1;
            text-transform: uppercase;
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;

      &__instagram {
        margin-bottom: auto;
        margin-top: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          path {
            stroke: $body_bg !important;
            // fill:$body_bg;
          }
        }
      }

      &__disclaimer {
        // white-space: nowrap;
        font-size: $base * 1.75;
      }
    }
  }
}
</style>
