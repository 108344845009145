<template>
  <section class="marquee" v-if="parsedPost">
    <div
      class="marquee__inner marquee__text"
      v-if="this.$root.$i18n.locale === 'es'"
    >
      {{ parsedPost.carousel_text.es }}
    </div>
    <div
      class="marquee__inner marquee__text"
      v-if="this.$root.$i18n.locale === 'ca'"
    >
      {{
        parsedPost.carousel_text.ca
          ? parsedPost.carousel_text.ca
          : parsedPost.carousel_text.es
      }}
    </div>
    <div
      class="marquee__inner marquee__text"
      v-if="this.$root.$i18n.locale === 'en'"
    >
      {{
        parsedPost.carousel_text.en
          ? parsedPost.carousel_text.en
          : parsedPost.carousel_text.es
      }}
    </div>
  </section>
</template>

<script>
import PostsService from "@/services/posts.service.js";

export default {
  props: {
    text: {
      type: String,
      default: "Esto es un texto por defecto",
    },
  },
  data() {
    return {
      homePageId: 89,
      parsedPost: null,
    };
  },
  created() {
    this.fetchPageContent();
  },
  watch: {
    "$root.$i18n.locale": function () {
      this.$nextTick(function () {
        this.animate();
      });
    },
  },
  mounted() {},
  methods: {
    async fetchPageContent() {
      try {
        // this.startLoading(); // actualizar el estado "loading" a false
        const post = await PostsService.getPage(this.homePageId);
        this.parsedPost = {
          carousel_text: {
            es: post.acf.text_es,
            en: post.acf.text_en,
            ca: post.acf.text_cat,
          },
          // my_custom_field: post.my_custom_field,
        };
      } finally {
        // this.stopLoading(); // actualizar el estado "loading" a false
        this.$nextTick(function () {
          this.animate();
        });
      }
    },
    animate() {
      const marquee = {
        textClass: "marquee__text",
        playClass: "marquee--play",
        spacer: " ", // here any spacer between the repeated fragements can be used

        getSpanWidth: function (text) {
          const t = document.createElement("span");
          t.classList.add(this.textClass);
          document.body.appendChild(t);

          t.style.height = "auto";
          t.style.width = "auto";
          t.style.position = "absolute";
          t.style.whiteSpace = "no-wrap";
          t.innerHTML = text + " ";

          const width = Math.ceil(t.clientWidth);
          document.body.removeChild(t);
          return width;
        },

        resizeMarquee: function (marquee) {
          if (!marquee.hasAttribute("data-times")) {
            marquee.innerHTML = "";
            marquee.setAttribute("data-times", 0);

            const style = getComputedStyle(marquee, "::before");
            marquee.setAttribute(
              "data-baseduration",
              parseFloat(style.animationDuration)
            );
          }

          const text = marquee.getAttribute("data-text").trim() + this.spacer;
          const wWidth = window.innerWidth;
          const times = Math.ceil(wWidth / this.getSpanWidth(text));

          if (times != marquee.getAttribute("data-times")) {
            marquee.setAttribute("data-times", times);
            marquee.setAttribute("data-multitext", "");
            for (let i = 0; i < times; i++) {
              marquee.setAttribute(
                "data-multitext",
                marquee.getAttribute("data-multitext") + text
              );
            }
          }

          // vary the animation duration to get a homogen linear movement
          marquee.style.animationDuration =
            (marquee.getAttribute("data-baseduration") / 1000) * wWidth + "s";
        },

        // eslint-disable-next-line no-unused-vars
        init: function (base) {
          const that = this;
          const marqueeElements = document.querySelectorAll(".marquee__inner");
          marqueeElements.forEach((marquee) => {
            const text = marquee.innerText + " ";
            marquee.setAttribute("data-text", text);

            that.resizeMarquee(marquee);

            marquee.classList.add(that.playClass);

            window.addEventListener("resize", function () {
              that.resizeMarquee(marquee);
            });
          });
        },
      };

      marquee.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  // this speed gets mainpulated in JS
  // it means symbolicly "how long it takes to scroll 1000px"
  // use "s" as unit
  --animation-base-time: 14.375s;

  --marquee-bg: transparent;
  --marquee-color: $body_color;
  --marquee-height: $base * 4;
  --marquee-fontsize: $base * 1.75;

  width: 100vw;
  max-width: 100%;
  height: var(--marquee-height);
  overflow: hidden;
  display: flex;
  align-items: center;

  &__inner {
    background-color: var(--marquee-bg);
    color: var(--marquee-color);
    height: var(--marquee-height);
    white-space: nowrap;

    &::before,
    &::after {
      animation: marquee linear 0s infinite paused;
      animation-duration: var(--animation-base-time);
      content: attr(data-multitext);
      padding-right: 0.25em;
    }
  }

  &--play {
    &::before,
    &::after {
      animation-play-state: running;
    }
  }
}

.marquee__text {
  font-size: var(--marquee-fontsize);
  display: flex;
  align-items: center;
  color: $body_color;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
