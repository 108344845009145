<template>
  <div class="event-gallery--swiper">
    <swiper
      :slidesPerView="1"
      :space-between="16"
      grabCursor
      :loop="false"
      :navigation="true"
      :modules="modules"
      :breakpoints="{
        '768': {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      }"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <div class="swiper-slide__img">
          <img :src="slide.image.sizes.large" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation],
    };
  },
};
</script>
<style lang="scss" scoped>
.event-gallery--swiper {
  width: 100%;
  // padding: 0 $base * 2;

  .swiper-slide {
    position: relative;
    min-height: calc(var(--app-height) * 0.2281059063);
    width: 100%;

    .swiper-slide__img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
<style lang="scss">
.event-gallery--swiper {
  .swiper {
    overflow: visible;
  }

  .swiper-wrapper {
    overflow: hidden;
  }

  .swiper-button-prev {
    top: 50%;
    left: -$base * 3.5;
    @media (min-width: 1024px) {
      top: -$base * 4;
      left: 0;
    }

    &:after {
      content: url(../../assets/svgs/icon-arrow-left.svg);
    }
  }

  .swiper-button-next {
    top: 50%;
    right: -$base * 3.5;
    @media (min-width: 1024px) {
      top: -$base * 4;
      right: 0;
    }

    &:after {
      content: url(../../assets/svgs/icon-arrow-right.svg);
    }
  }
}
</style>
