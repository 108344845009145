<template>
  <div class="skeleton-people-card"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-people-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 1/1;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba($body_color_rgb, 0.7),
    rgba($body_color_rgb, 0.9),
    rgba($body_color_rgb, 0.7)
  );
  background-size: 200%;
  animation: loader 1s infinite reverse;
}
/* skeleton animation */
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}
</style>
