<template>
  <section class="structure-section">
    <div class="structure-section--header">
      <h2
        v-if="
          this.$root.$i18n.locale === 'es' && this.structurePageContent.title
        "
      >
        {{ structurePageContent.title.es }}
      </h2>
      <h2
        v-if="
          this.$root.$i18n.locale === 'ca' && this.structurePageContent.title
        "
      >
        {{
          structurePageContent.title.ca
            ? structurePageContent.title.ca
            : structurePageContent.title.es
        }}
      </h2>
      <h2
        v-if="
          this.$root.$i18n.locale === 'en' && this.structurePageContent.title
        "
      >
        {{
          structurePageContent.title.en
            ? structurePageContent.title.en
            : structurePageContent.title.es
        }}
      </h2>
      <a :href="this.structurePageContent.link" target="__blank"
        >{{ $t("structure_link_text") }} →</a
      >
    </div>
    <!-- <pre style="background-color: white">{{ structurePageContent }}</pre> -->
    <div class="structure-section--content">
      <div class="structure-section--content__left">
        <div
          class="structure-section--content__left__text"
          v-if="
            this.$root.$i18n.locale === 'es' &&
            this.structurePageContent.excerpt
          "
          v-html="this.structurePageContent.excerpt.es"
        ></div>
        <div
          class="structure-section--content__left__text"
          v-if="
            this.$root.$i18n.locale === 'ca' &&
            this.structurePageContent.excerpt
          "
          v-html="
            this.structurePageContent.excerpt.ca
              ? this.structurePageContent.excerpt.ca
              : this.structurePageContent.excerpt.es
          "
        ></div>
        <div
          class="structure-section--content__left__text"
          v-html="
            this.structurePageContent.excerpt.en
              ? this.structurePageContent.excerpt.en
              : this.structurePageContent.excerpt.es
          "
          v-if="
            this.$root.$i18n.locale === 'en' &&
            this.structurePageContent.excerpt
          "
        ></div>
        <div class="structure-section--content__left__button">
          <router-link :to="{ name: 'Structure' }">
            {{ $t("know_more") }}
          </router-link>
        </div>
      </div>
      <div
        v-if="!this.$vuetify.display.mobile"
        class="structure-section--content__right"
      >
        <div
          class="structure-section--content__right__img"
          v-if="this.structurePageContent.image"
        >
          <img :src="this.structurePageContent.image.full" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sanitizeHtml from "sanitize-html";
import PostsService from "@/services/posts.service.js";

export default {
  data() {
    return {
      structurePageId: 541,
      structurePageContent: [],
      loading: false,
    };
  },
  created() {
    this.fetchStructurePage();
  },
  methods: {
    async fetchStructurePage() {
      this.loading = true;
      this.structurePageContent = await PostsService.getPage(
        this.structurePageId
      )
        .then(async (res) => {
          const excerptEs = sanitizeHtml(res.acf.content.content_es, {
            allowedTags: [
              "p",
              "a",
              "strong",
              "h1",
              "h2",
              "h3",
              "h4",
              "h5",
              "h6",
              "ol",
              "ul",
              "li",
            ],
            allowedAttributes: [],
          });
          const excerptEn = sanitizeHtml(res.acf.content.content_en, {
            allowedTags: [
              "p",
              "a",
              "strong",
              "h1",
              "h2",
              "h3",
              "h4",
              "h5",
              "h6",
              "ol",
              "ul",
              "li",
            ],
            allowedAttributes: [],
          });
          const excerptCa = sanitizeHtml(res.acf.content.content_cat, {
            allowedTags: [
              "p",
              "a",
              "strong",
              "h1",
              "h2",
              "h3",
              "h4",
              "h5",
              "h6",
              "ol",
              "ul",
              "li",
            ],
            allowedAttributes: [],
          });

          const excerptLimit = this.$vuetify.display.mobile ? 1 : 0.8;
          const result = {
            title: {
              es: res.acf.name.name_es,
              en: res.acf.name.name_en,
              ca: res.acf.name.name_cat,
            },
            content: {
              es: res.acf.content.content_es,
              en: res.acf.content.content_en,
              ca: res.acf.content.content_cat,
            },
            excerpt: {
              es:
                excerptEs.length > this.$vuetify.display.height * excerptLimit
                  ? excerptEs.substring(
                      0,
                      this.$vuetify.display.height * excerptLimit
                    ) + "..."
                  : excerptEs.substring(
                      0,
                      this.$vuetify.display.height * excerptLimit
                    ),
              en:
                excerptEn.length > this.$vuetify.display.height * excerptLimit
                  ? excerptEn.substring(
                      0,
                      this.$vuetify.display.height * excerptLimit
                    ) + "..."
                  : excerptEn.substring(
                      0,
                      this.$vuetify.display.height * excerptLimit
                    ),
              ca:
                excerptCa.length > this.$vuetify.display.height * excerptLimit
                  ? excerptCa.substring(
                      0,
                      this.$vuetify.display.height * excerptLimit
                    ) + "..."
                  : excerptCa.substring(
                      0,
                      this.$vuetify.display.height * excerptLimit
                    ),
            },
            image: {
              full: res.acf.image ? res.acf.image.url : null,
              large: res.acf.image ? res.acf.image.sizes.large : null,
            },
            link: res.acf.external_link,
            slug: res.slug,
            id: res.id,
            // otros campos
          };
          // console.log(result); // Aquí se imprime el objeto
          return result;
        })
        .finally(() => {
          this.loading = false;
        });
      return this.structurePageContent; // Devuelve la propiedad
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }
section.structure-section {
  padding-top: $base * 9;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.06433808554),
    #{$base} * 11
  );
  padding-right: $base * 2;
  padding-left: $base * 2;
  padding-bottom: $base * 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $base * 10;

  @media (min-width: 1024px) {
    height: var(--app-height);
    scroll-snap-align: start;
    padding-right: 10.25132275vw;
    padding-left: 10.25132275vw;
    margin-bottom: 0;
  }

  .structure-section--header {
    // padding-top: calc(var(--app-height) * 0.06433808554);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $base * 4;

    @media (min-width: 1024px) {
      font-size: $base * 8.5;
      line-height: $base * 9;
      // flex: 1 1 auto;
      margin-bottom: 0;
    }

    h2 {
      font-size: $base * 4;
      line-height: $base * 5;
      color: $body_color;
      font-family: $font_family_alt;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: $base * 3.75;

      @media (min-width: 1024px) {
        margin-top: $base * 4;
        font-size: $base * 8.5;
        line-height: $base * 9;
        margin-bottom: 0;
      }
    }

    a {
      font-family: $font_family_alt;
      transition: color 0.2s ease;
      color: rgba($body_color_rgb, 0.7);
      font-size: $base * 2;
      line-height: $base * 3;
      margin-bottom: $base * 4;

      &:hover {
        color: $corporate_color;
      }
    }
  }

  .structure-section--content {
    display: flex;
    flex-wrap: nowrap;
    gap: 4.62962963vw;
    margin-bottom: auto;

    &__left {
      color: $body_color;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: $base * 2;
      flex: 1 1 auto;

      &__text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: $font_family_alt;
          font-weight: normal;
          text-transform: uppercase;
          margin-bottom: $base;
        }

        p {
          font-family: $font_family_base;
        }
      }

      &__button {
        flex: 0.2 1 auto;

        a {
          width: fit-content;
          display: block;
          font-size: $base * 2;
          line-height: $base * 2;
          color: $body_color;
          font-family: $font_family_alt;
          padding: $base * 1.2 $base * 2;
          padding-bottom: $base * 0.8;
          text-transform: uppercase;
          font-weight: normal;
          text-decoration: none;
          border: 1px solid $body_color;
          border-radius: 500px;

          @media (min-width: 1024px) {
            font-size: $base * 3;
            line-height: $base * 3;
          }
        }
      }
    }

    &__right {
      width: 50%;

      &__img {
        width: 100%;
        aspect-ratio: 1/1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.structure-section--content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font_family_alt;
    font-weight: 400;
    text-transform: uppercase;
  }
}
</style>
