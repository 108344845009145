<template>
  <div class="loader">
    <div class="loader--title"><span class="bold">DPA</span>UPV</div>
    <div class="loader--subtitle">{{ $t("loader_subtitle") }}</div>
    <div class="loader--anim">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Loader mounted");
  },
};
</script>

<style lang="scss" scoped>
.loader {
  --loader-width: 140px;
  --loader-size: 19px;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $body_color;
  color: $body_bg;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $base * 2;

  &--title {
    font-size: $base * 8;
    line-height: 1;
    font-weight: 400;
    font-family: $font_family_alt;
    margin-bottom: 0;
    text-align: center;
    color: $body_bg;
    @media (min-width: 1024px) {
      font-size: $base * 16;
    }

    & .bold {
      font-weight: 700;
    }
  }
  &--subtitle {
    font-family: $font_family_alt;
    margin-top: -$base * 2;
    font-size: $base * 2;
    text-align: center;
    max-width: $base * 35;
    @media (min-width: 1024px) {
      font-size: $base * 2.5;
      margin-top: -$base * 4;
    }
  }

  &--anim {
    position: relative;
    /* background: #f0f0f0; */
    width: var(--loader-width);
    height: var(--loader-size);

    span {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      width: var(--loader-size);
      height: var(--loader-size);
      border-radius: 50%;
      opacity: 0;
      animation: loader-anim forwards 2.5s infinite ease-in-out;

      &:nth-child(2) {
        animation-delay: 0.75s;
      }

      &:nth-child(3) {
        animation-delay: 1.5s;
      }
    }
  }

  @keyframes loader-anim {
    0% {
      transform: translateX(0);
      opacity: 0;
    }

    10% {
      transform: translateX(0);
      opacity: 0.25;
    }

    25% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.75;
    }

    75% {
      opacity: 0.25;
    }

    90% {
      transform: translateX(calc(var(--loader-width) - var(--loader-size)));
      opacity: 0.25;
    }

    100% {
      transform: translateX(calc(var(--loader-width) - var(--loader-size)));
      opacity: 0;
    }
  }
}
</style>
