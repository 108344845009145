<template>
  <div>
    <div class="top-bar-mobile">
      <div class="top-bar-mobile__inner">
        <div class="top-bar-mobile--logo">
          <top-bar-logo />
        </div>
        <div class="top-bar-mobile--burger">
          <div
            class="top-bar-mobile--burger--icon"
            :class="[menuOpen ? 'open' : 'close']"
            @click="toggleMenu"
          >
            <div class="top-bar-mobile--burger--icon__line" />
            <div class="top-bar-mobile--burger--icon__line" />
            <div class="top-bar-mobile--burger--icon__line" />
          </div>
        </div>
        <div class="top-bar-mobile--archive">
          <a class="top-bar-mobile--archive__button" @click="toggleArchive">
            {{ $t("archive") }}
          </a>
          <div
            class="top-bar-mobile--archive__overlay"
            :class="[archiveOpen ? 'open' : 'close']"
          >
            <div class="top-bar-mobile--archive__overlay__inner">
              <a
                href="https://archivedpa.webs.upv.es/archive/"
                target="_blank"
                class="top-bar-mobile--archive__overlay__inner__link"
                @click="toggleArchive"
              >
                <p>{{ $t("archive_cta") }}</p>
              </a>
              <a
                class="top-bar-mobile--archive__overlay__inner__close"
                @click="toggleArchive"
              >
                <img
                  :src="require('@/assets/svgs/icon-close-white.svg')"
                  alt="close"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <mobile-menu ref="mobileMenu" @toggle="updateMenuState()" />
    </div>
  </div>
</template>

<script>
import TopBarLogo from "@/components/TopBarLogo.vue";
import MobileMenu from "@/components/layout/MobileMenu.vue";

export default {
  components: {
    TopBarLogo,
    MobileMenu,
  },
  data() {
    return {
      menuOpen: false,
      archiveOpen: false,
    };
  },
  methods: {
    // toggleMenu outside of the component
    toggleMenu() {
      this.$refs.mobileMenu.toggleMenu();
      this.menuOpen = !this.menuOpen;
      // avoid body to scroll
      if (this.menuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    // update the menu state
    updateMenuState(state) {
      this.menuOpen = state;
    },
    toggleArchive() {
      this.archiveOpen = !this.archiveOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
// *{
//     border:solid 1px red;
// }
.top-bar-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding: $base $base * 2;
    background: $body_bg;
    .top-bar-mobile--logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
    }
    .top-bar-mobile--burger {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2;
      width: $base * 5;
      height: $base * 4;
      cursor: pointer;
      .top-bar-mobile--burger--icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        max-height: $base * 4;
        max-width: $base * 5;
        position: relative;
        overflow: hidden;
        .top-bar-mobile--burger--icon__line {
          width: 100%;
          height: 4px;
          background-color: $body_color;
          transition: all 0.3s ease-out;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 300;

          &:nth-child(1) {
            top: 20%;
          }

          &:nth-child(3) {
            top: 80%;
          }
        }
        &.open {
          .top-bar-mobile--burger--icon__line {
            &:nth-child(1) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-child(2) {
              position: absolute;
              top: 50%;
              transform: rotate(0) translate(100%, -50%);
            }
            &:nth-child(3) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      }
    }
    .top-bar-mobile--archive {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;

      cursor: pointer;
      &__button {
        font-size: $base * 2.5;
        line-height: $base * 3.4;
        font-weight: normal;
        font-family: $font_family_alt;
        background: $body_color;
        color: $body_bg;
        border-radius: 500px;
        text-transform: uppercase;
        padding: $base * 0.5 $base;
        height: $base * 4;
      }
      &__overlay {
        position: fixed;
        top: $base * 6;
        right: 0;
        z-index: 300;
        background: #030303;
        max-width: $base * 21.75;
        transition: all 0.3s ease-out;
        &.open {
          transform: translateX(0);
        }
        &.close {
          transform: translateX(100%);
        }

        &__inner {
          position: relative;
          padding: $base * 2;
          &__link {
            color: $corporate_color;
            font-size: $base * 2.5;
            line-height: $base * 3;
            font-family: $font_family_alt;
            text-transform: uppercase;
            p {
              margin-bottom: 0;
              span {
                font-size: $base * 3.3;
                line-height: $base * 3;
                font-family: $font_family_alt;
                text-transform: uppercase;
              }
            }
          }
          &__close {
            position: absolute;
            top: $base;
            right: $base * 0.5;
          }
        }
      }
    }
  }
}
</style>
