<template>
  <div class="top-bar">
    <top-bar-logo />
    <marquee-animated v-if="!this.$vuetify.display.mobile" />
    <archive-cta />
  </div>
</template>

<script>
import MarqueeAnimated from "@/components/MarqueeAnimated.vue";
import TopBarLogo from "@/components/TopBarLogo.vue";
import ArchiveCta from "@/components/ArchiveCta.vue";

export default {
  components: {
    MarqueeAnimated,
    TopBarLogo,
    ArchiveCta,
  },
  // props:{
  //     backgroundColor:{
  //         type: String,
  //         default: 'transparent'
  //     }
  // },
  // data() {
  //     return {
  //         previousScroll: 0,
  //     }
  // },
  // created () {
  //     window.addEventListener('scroll', this.handleScroll)
  // },
  // destroyed () {
  //     window.removeEventListener('scroll', this.handleScroll)
  // },
  // methods:{
  //     handleScroll() {
  //         const langSelector = document.querySelector('.top-bar')
  //         const scrollPosition = window.scrollY
  //         if(scrollPosition === 0){
  //             langSelector.classList.remove('shadow')
  //         }
  //         else if (scrollPosition > this.previousScroll && scrollPosition > 300) {
  //             langSelector.classList.add('hidden')
  //             langSelector.classList.add('shadow')
  //         } else {
  //             langSelector.classList.remove('hidden')
  //             langSelector.classList.add('shadow')
  //         }
  //         this.previousScroll = scrollPosition
  //     },
  // }
};
</script>

<style lang="scss" scoped>
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: $base * 6;
  padding: $base $base * 2;
  //   padding: $base 2.116402116vw;
  display: flex;
  justify-content: space-between;
  column-gap: $base * 3;
  z-index: 11;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.5s linear;
  transform: translateY(0%);
  box-shadow: none;
  background: rgba($body_bg_rgb, 1);
  @media (min-width: 1024px) {
    padding: $base $base * 4;
  }
  &.hidden {
    transform: translateY(-100%);
    box-shadow: none;
  }
  &.shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background: rgba($body_bg_rgb, 0.8);
  }
}
</style>
<style lang="scss"></style>
