<template>
  <div class="related-events--swiper">
    <swiper
      :slidesPerView="1"
      :space-between="16"
      grabCursor
      :loop="false"
      :navigation="true"
      :modules="modules"
      :breakpoints="{
        '768': {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      }"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <router-link
          :to="'/events/' + slide.id + '/' + slide.slug + '/'"
          class="swiper-slide--link"
        >
          <!-- Render original HTML in server, render Swiper in browser (client) -->
          <div class="swiper-slide--link__img">
            <img :src="slide.image.large" />
          </div>
          <div class="swiper-slide--link__info">
            <span
              class="swiper-slide--link__info__icon"
              v-html="slide.typology.icon"
            >
            </span>
            <div
              class="swiper-slide--link__info__title"
              v-if="this.$root.$i18n.locale == 'es'"
            >
              {{ slide.title.es }}
            </div>
            <div
              class="swiper-slide--link__info__title"
              v-else-if="this.$root.$i18n.locale == 'en'"
            >
              {{ slide.title.en ? slide.title.en : slide.title.es }}
            </div>
            <div
              class="swiper-slide--link__info__title"
              v-else-if="this.$root.$i18n.locale == 'ca'"
            >
              {{ slide.title.ca ? slide.title.ca : slide.title.es }}
            </div>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation],
    };
  },
};
</script>
<style lang="scss" scoped>
.related-events--swiper {
  width: 100%;
  .swiper-slide {
    position: relative;
    height: calc(var(--app-height) * 0.2281059063);
    width: 100%;
    &--link {
      display: block;
      height: 100%;
      width: 100%;
      &__img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      &__info {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba($body_color_rgb, 0.6);
        transition: all 0.3s ease;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: $base;
        padding: $base;
        color: $body_bg;
        font-family: $font_family_alt;
        font-size: $base * 1.875;
        @media (min-width: 1024px) {
          padding: $base * 1.5;
          bottom: -100%;
          visibility: hidden;
          opacity: 0;
          font-size: $base * 2.5;
        }
      }
      &:hover {
        .swiper-slide--link__info {
          @media (min-width: 1024px) {
            bottom: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.related-events--swiper {
  .swiper {
    overflow: visible;
  }

  .swiper-wrapper {
    overflow-y: visible;
    // overflow: hidden;
  }
  .swiper-slide {
    overflow: hidden;
    &--link {
      &__info {
        svg {
          path {
            fill: $body_bg;
          }
        }
      }
    }
  }
  .swiper-button-prev {
    top: -$base * 4;
    left: 0;
    @media (min-width: 1024px) {
      top: 50%;
      left: -$base * 5;
    }

    &:after {
      content: url(../../assets/svgs/icon-arrow-left.svg);
    }
  }

  .swiper-button-next {
    top: -$base * 4;
    right: 0;
    @media (min-width: 1024px) {
      top: 50%;
      right: -$base * 5;
    }

    &:after {
      content: url(../../assets/svgs/icon-arrow-right.svg);
    }
  }
}
</style>
