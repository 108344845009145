<template>
  <div class="page page-units">
    <div class="units--content">
      <h2 class="units--content__title">
        {{ $t("our") }} <span>{{ $t("units") }}</span>
      </h2>
      <div class="units--content__list" v-if="parsedPosts">
        <template v-if="this.$root.$i18n.locale === 'es'">
          <unit-program-card
            v-for="(unit, index) in parsedPosts.es"
            :key="index"
            :unit="unit"
          ></unit-program-card>
        </template>
        <template v-if="this.$root.$i18n.locale === 'ca'">
          <unit-program-card
            v-for="(unit, index) in parsedPosts.ca
              ? parsedPosts.ca
              : parsedPosts.es"
            :key="index"
            :unit="unit"
          ></unit-program-card>
        </template>
        <template v-if="this.$root.$i18n.locale === 'en'">
          <unit-program-card
            v-for="(unit, index) in parsedPosts.en
              ? parsedPosts.en
              : parsedPosts.es"
            :key="index"
            :unit="unit"
          ></unit-program-card>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/posts.service.js";

import UnitProgramCard from "@/components/units/UnitProgramCard.vue";
export default {
  name: "UnitsView",
  components: {
    UnitProgramCard,
  },
  data() {
    return {
      parsedPosts: {},
      isLoading: false,
      pagination: {
        page: 1,
        perPage: 100,
        order: "asc",
        orderBy: "title",
      },
    };
  },
  created() {
    this.fetchUnits();
    document.title = this.$t("metaTitles.units") + "| DPA UPV";
  },
  methods: {
    async fetchUnits() {
      console.log("fetching units");
      this.isLoading = true;
      const rawUnits = await PostsService.getPaginationUnits(
        this.pagination.page,
        this.pagination.perPage,
        this.pagination.order,
        this.pagination.orderBy
      );

      const parsedPosts = {
        es: [],
        en: [],
        ca: [],
      };

      rawUnits.forEach((rawUnit) => {
        parsedPosts.es.push({
          id: rawUnit.id,
          slug: rawUnit.slug,
          acronym: rawUnit.title.rendered,
          name: rawUnit.acf.unit_name.name_es,
          cover_image: {
            src: rawUnit.acf.cover_image.sizes.large,
            alt: rawUnit.acf.unit_name.name_es,
          },
        });
        parsedPosts.en.push({
          id: rawUnit.id,
          slug: rawUnit.slug,
          acronym: rawUnit.title.rendered,
          name: rawUnit.acf.unit_name.name_en,
          cover_image: {
            src: rawUnit.acf.cover_image.sizes.large,
            alt: rawUnit.acf.unit_name.name_en,
          },
        });
        parsedPosts.ca.push({
          id: rawUnit.id,
          slug: rawUnit.slug,
          acronym: rawUnit.title.rendered,
          name: rawUnit.acf.unit_name.name_cat,
          cover_image: {
            src: rawUnit.acf.cover_image.sizes.large,
            alt: rawUnit.acf.unit_name.name_cat,
          },
        });
      });

      this.parsedPosts = parsedPosts;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-units {
  // height: var(--app-height);
  padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;
  margin-bottom: $base * 4;
  min-height: var(--app-height);

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    column-gap: $base * 3;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.units--content {
  grid-column: 1 / span 12;
  height: 100%;
  width: calc(100% + #{$base * 4});
  margin-left: -$base * 2;
  padding-bottom: $base * 5;

  @media (min-width: 1024px) {
    padding-top: $base * 4;
    padding-bottom: calc(var(--app-height) * 0.2454175153);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: $base * 3;
  }

  &__title {
    grid-column: 2 / span 10;
    font-size: $base * 5;
    line-height: $base * 5.5;
    font-family: $font_family_alt;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    margin-bottom: $base * 3;
    padding-left: $base * 2;
    color: $body_color;

    @media (min-width: 1024px) {
      padding-left: 0;
      font-size: $base * 8.5;
      line-height: $base * 9;
      color: $body_bg;
      mix-blend-mode: difference;
      margin-bottom: $base * 8;
    }
  }

  &__list {
    grid-column: 2 / span 10;
    display: flex;
    flex-direction: column;
    gap: $base * 2;

    @media (min-width: 1024px) {
      gap: $base * 3;
    }
  }
}
</style>
