<template>
  <router-link
    class="people-card"
    v-if="item.roles.translations.name_en === 'Faculty'"
    :to="'/people/' + item.id + '/' + item.slug + '/'"
  >
    <div class="people-card__img">
      <img :src="item.image.src" :alt="item.image.alt" v-if="item.image.src" />
      <img
        class="placeholder-img"
        :src="require('@/assets/svgs/no-picture.svg')"
        alt=""
        v-else
      />
    </div>
    <div class="people-card__overlay" />

    <div class="people-card__info">
      <h3 class="people-card__info__initials" v-if="item.fullName">
        {{ getInitials(item.fullName) }}
      </h3>
      <div class="people-card__info__category" v-if="item.category">
        {{ item.category }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'es'"
      >
        {{ item.roles.translations.name_es }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'ca'"
      >
        {{
          item.roles.translations.name_ca
            ? item.roles.translations.name_ca
            : item.roles.translations.name_es
        }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'en'"
      >
        {{
          item.roles.translations.name_en
            ? item.roles.translations.name_en
            : item.roles.translations.name_es
        }}
      </div>
    </div>
  </router-link>
  <a
    :href="item.link"
    target="__blank"
    class="people-card"
    v-if="item.roles.translations.name_en === 'Student'"
  >
    <div class="people-card__img">
      <img :src="item.image.src" :alt="item.image.alt" v-if="item.image.src" />
      <img
        class="placeholder-img"
        :src="require('@/assets/svgs/no-picture.svg')"
        alt=""
        v-else
      />
    </div>
    <div class="people-card__overlay" />
    <div class="people-card__info">
      <h3 class="people-card__info__initials" v-if="item.fullName">
        {{ getInitials(item.fullName) }}
      </h3>
      <div class="people-card__info__category" v-if="item.category">
        {{ item.category }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'es'"
      >
        {{ item.roles.translations.name_es }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'ca'"
      >
        {{
          item.roles.translations.name_ca
            ? item.roles.translations.name_ca
            : item.roles.translations.name_es
        }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'en'"
      >
        {{
          item.roles.translations.name_en
            ? item.roles.translations.name_en
            : item.roles.translations.name_es
        }}
      </div>
    </div>
  </a>
  <div class="people-card" v-if="item.roles.translations.name_en === 'Staff'">
    <div class="people-card__img">
      <img :src="item.image.src" :alt="item.image.alt" v-if="item.image.src" />
      <img
        class="placeholder-img"
        :src="require('@/assets/svgs/no-picture.svg')"
        alt=""
        v-else
      />
    </div>
    <div class="people-card__overlay" />

    <div class="people-card__info">
      <h3 class="people-card__info__initials" v-if="item.fullName">
        {{ getInitials(item.fullName) }}
      </h3>
      <div class="people-card__info__category" v-if="item.category">
        {{ item.category }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'es'"
      >
        {{ item.roles.translations.name_es }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'ca'"
      >
        {{
          item.roles.translations.name_ca
            ? item.roles.translations.name_ca
            : item.roles.translations.name_es
        }}
      </div>
      <div
        class="people-card__info__category"
        v-if="item.roles && this.$root.$i18n.locale === 'en'"
      >
        {{
          item.roles.translations.name_en
            ? item.roles.translations.name_en
            : item.roles.translations.name_es
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getInitials(name) {
      let initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      //add . between characters
      initials = initials.split("").join(".");
      return initials;
    },
  },
};
</script>

<style lang="scss" scoped>
.people-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 1/1;
  cursor: pointer;
  &:hover {
    .people-card__img {
      img {
        filter: grayscale(100%);
      }
    }
    .people-card__info {
      opacity: 1;
      visibility: visible;
    }
    .people-card__overlay {
      opacity: 0.6;
      visibility: visible;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      filter: grayscale(100%);

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $body_bg;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1;
    color: $body_color;

    &__initials {
      font-size: $base * 6;
      line-height: $base * 6;
      font-family: $font_family_alt;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    &__category {
      font-size: $base * 2.5;
      line-height: 1;
      text-transform: uppercase;
    }
  }
}
// .placeholder-img {
//   mix-blend-mode: difference;
// }
</style>
