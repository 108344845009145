<template>
  <router-link
    :to="'/events/' + event.id + '/' + event.slug"
    class="search-event-card"
  >
    <div class="search-event-card--image">
      <img :src="event.cover_image.full" alt="event image" />
    </div>
    <div class="search-event-card--gradient"></div>
    <div class="search-event-card--info">
      <div class="search-event-card--info__left">
        <h3 class="search-event-card--info__left__title">
          {{ event.title }}
        </h3>
        <div
          class="search-event-card--info__left__location"
          v-if="this.$root.$i18n.locale == 'ca' && event.location"
        >
          {{ event.location.ca ? event.location.ca : event.location.es }}
        </div>
        <div
          class="search-event-card--info__left__location"
          v-if="this.$root.$i18n.locale == 'es' && event.location"
        >
          {{ event.location.es }}
        </div>
        <div
          class="search-event-card--info__left__location"
          v-if="this.$root.$i18n.locale == 'en' && event.location"
        >
          {{ event.location.en ? event.location.en : event.location.es }}
        </div>
        <div
          class="search-event-card--info__left__date"
          v-if="this.$vuetify.display.mobile"
        >
          {{ event.date }}
        </div>
        <div
          class="search-event-card--info__left__excerpt"
          v-if="this.$root.$i18n.locale == 'es' && event.content"
        >
          <div v-html="event.content.es"></div>
        </div>
        <div
          class="search-event-card--info__left__excerpt"
          v-if="this.$root.$i18n.locale == 'en' && event.content"
        >
          <div
            v-html="event.content.en ? event.content.en : event.content.es"
          ></div>
        </div>
        <div
          class="search-event-card--info__left__excerpt"
          v-if="this.$root.$i18n.locale == 'ca' && event.content"
        >
          <div
            v-html="event.content.ca ? event.content.ca : event.content.es"
          ></div>
        </div>
      </div>
      <div class="search-event-card--info__right">
        <div class="search-event-card--info__right__typology">
          <span class="svg" v-html="event.typology.icon"></span>
          <span v-if="this.$root.$i18n.locale == 'ca'">{{
            event.typology.ca ? event.typology.ca : event.typology.es
          }}</span>
          <span v-if="this.$root.$i18n.locale == 'es'">{{
            event.typology.es
          }}</span>
          <span v-if="this.$root.$i18n.locale == 'en'">{{
            event.typology.en ? event.typology.en : event.typology.es
          }}</span>
        </div>
        <div
          class="search-event-card--info__right__date"
          v-if="!this.$vuetify.display.mobile"
        >
          {{ event.date }}
        </div>
        <div
          class="search-event-card--info__right__button"
          v-if="!this.$vuetify.display.mobile"
        >
          <svg
            width="26"
            height="44"
            viewBox="0 0 26 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 3L22.51 22L3 41"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-event-card {
  position: relative;
  height: 100%;
  min-height: $base * 30;
  max-height: $base * 30;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;

  &--image {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--gradient {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(
      180deg,
      $body_color 0%,
      rgba($body_color_rgb, 0) 100%
    );
    @media (min-width: 1024px) {
      max-height: 40%;
    }
  }

  &--info {
    padding: $base * 1.5;
    padding-bottom: 0;
    z-index: 2;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    @media (min-width: 1024px) {
      padding: $base * 3;
      padding-bottom: $base * 5;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: $base * 3;
    }

    &__left {
      grid-column: 1 / span 9;
      display: flex;
      flex-direction: column;
      height: 100%;

      &__title {
        font-size: $base * 2;
        line-height: 1;
        font-weight: 400;
        font-family: $font_family_alt;
        color: $body_bg;
        text-transform: uppercase;
        margin: 0;
        @media (min-width: 1024px) {
          font-size: $base * 5;
          line-height: 1;
        }
      }

      &__location {
        font-size: $base * 1.5;
        line-height: 1;
        font-weight: normal;
        color: $body_bg;
        margin-bottom: $base;
        margin-top: $base;
        @media (min-width: 1024px) {
          margin-top: 0;
          font-size: $base * 3;
          line-height: 1;
        }
      }
      &__date {
        font-size: $base * 1.5;
        line-height: 1;
        color: $body_bg;
      }

      &__excerpt {
        display: none;
        font-size: $base * 2.25;
        line-height: $base * 3;
        font-weight: normal;
        color: $body_bg;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-$base * 3);
        transition: all 0.3s ease-in-out;
        @media (min-width: 1024px) {
          display: block;
        }
      }
    }
    &__right {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba($body_color_rgb, 0.4);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: auto;
      min-height: auto;
      padding: $base;
      @media (min-width: 1024px) {
        background-color: rgba($body_color_rgb, 0);
        position: static;
        grid-column: 10 / span 3;
        width: auto;
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: $base * 2;
      }

      &__typology {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: $base * 0.5;
        span {
          font-size: $base * 1.75;
          line-height: 1;
          font-weight: normal;
          font-family: $font_family_alt;
          text-transform: uppercase;
          color: $body_bg;
          white-space: nowrap;
          @media (min-width: 1024px) {
            font-size: $base * 2.625;
          }
        }
      }
      &__date {
        font-size: $base * 2.625;
        line-height: 1;
        font-weight: normal;
        font-family: $font_family_alt;
        text-transform: uppercase;
        color: $body_bg;
        margin-bottom: auto;
      }
      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $base * 5;
        height: $base * 5;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin-top: auto;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-$base * 3);
        transition: all 0.3s ease-in-out;

        svg {
          path {
            stroke: $body_bg;
          }
        }
      }
    }
  }
  &:hover {
    .search-event-card--info__left__excerpt {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    .search-event-card--info__right__button {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
    .search-event-card--gradient {
      max-height: 100%;
    }
  }
}
</style>
<style lang="scss">
.search-event-card--info__left__excerpt {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font_family_alt;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    line-height: 1;
  }
}

.search-event-card--info__right__typology {
  span.svg {
    svg {
      path {
        fill: $body_bg !important;
      }
    }
  }
}
</style>
