<template>
  <div class="daily-toggle">
    <router-link :to="!isDailyOpen ? '/events' : '/'">
      <span class="daily-toggle__text" v-if="!isDailyOpen">
        <svg
          width="31"
          height="34"
          viewBox="0 0 31 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.48 -1.52588e-05H0V33.84H12.48C21.504 33.84 30.912 31.488 30.912 16.896C30.912 2.35199 21.504 -1.52588e-05 12.48 -1.52588e-05ZM12.48 27.12H7.68V6.71999H12.48C18.48 6.71999 23.232 7.19999 23.232 16.896C23.232 26.64 18.48 27.12 12.48 27.12Z"
            fill="#D9D9D9"
          />
        </svg>
      </span>
      <div class="daily-toggle__close" v-else>
        <span class="daily-toggle__close__line"></span>
        <span class="daily-toggle__close__line"></span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DailyToggle",
  props: {
    initialIsToggleOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDailyOpen: this.initialIsToggleOpen,
    };
  },
  computed: {
    computedIsToggleOpen: {
      get() {
        return this.isDailyOpen;
      },
      set(value) {
        this.isDailyOpen = value;
      },
    },
  },
  watch: {
    initialIsToggleOpen(newValue) {
      this.isDailyOpen = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-toggle {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: $base * 8;
  max-height: $base * 8;
  z-index: 100;
  a {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .daily-toggle__text {
        background: rgba($corporate_color_rgb, 0.7);
      }
    }
    .daily-toggle__text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //   background: rgba($corporate_color_rgb, 0.7);
      background: rgba($body_color_rgb, 0.8);
      svg {
        path {
          fill: $body_bg;
        }
      }
    }
    .daily-toggle__close {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($corporate_color_rgb, 0.7);
      &__line {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        height: 4px;
        background: $body_bg;
        transform: translate(-50%) rotate(45deg);
        &:nth-child(2) {
          transform: translate(-50%) rotate(-45deg);
        }
      }
    }
  }
}
</style>
