<template>
  <v-app>
    <main id="main" :class="this.$store.state.activeTheme">
      <top-bar v-if="!this.$vuetify.display.mobile" background-color="white" />
      <top-bar-mobile v-else />
      <burger-menu v-if="!this.$vuetify.display.mobile" />
      <Transition>
        <loader-component v-if="isLoading"></loader-component>
      </Transition>
      <!-- <grid-layout
        :cols="12"
        :gap="24"
        :base="16"
        :margin="32"
        :enabled="false"
      ></grid-layout> -->
      <router-view class="page" />
      <daily-toggle :initialIsToggleOpen="isDailyOpen" />
      <footer-mobile v-if="this.$vuetify.display.mobile"></footer-mobile>
      <footer-desktop v-if="!this.$vuetify.display.mobile"></footer-desktop>
    </main>
  </v-app>
</template>
<script>
/*eslint-disable no-unused-vars */
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { mapGetters } from "vuex";
import { useStore } from "vuex";

// import GridLayout from "@/components/layout/GridLayout.vue";

import TopBar from "@/components/layout/TopBar.vue";
import TopBarMobile from "@/components/layout/TopBarMobile.vue";
import BurgerMenu from "@/components/layout/BurgerMenu.vue";
import FooterMobile from "@/components/layout/FooterMobile.vue";
import FooterDesktop from "@/components/layout/FooterDesktop.vue";
import DailyToggle from "@/components/layout/DailyToggle.vue";
import LoaderComponent from "@/components/layout/LoaderComponent.vue";

export default {
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters.isLoading);
    const siteData = reactive({
      title: `DPA UPV`,
      description: `My beautiful website`,
    });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description),
        },
        {
          property: "og:title",
          content: computed(() => siteData.title),
        },
        {
          property: "og:description",
          content: computed(() => siteData.description),
        },
        {
          property: "og:url",
          content: "https://dpa.mdtec.es",
        },
        {
          property: "og:image",
          content: "https://dpa.mdtec.es/DPAUPVOGIMAGE.png",
        },
      ],
    });
  },
  components: {
    TopBar,
    TopBarMobile,
    BurgerMenu,
    FooterMobile,
    FooterDesktop,
    DailyToggle,
    LoaderComponent,
    // GridLayout,
  },
  data() {
    return {
      isDailyOpen: false,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name === "EventsCalendar" || to.name === "EventsSearch") {
        this.isDailyOpen = true;
      } else {
        this.isDailyOpen = false;
      }
      next();
    });
  },
  beforeUnmount() {
    this.$router.beforeEach((to, from, next) => {});
  },
  mounted() {
    this.$nextTick(function () {
      this.onResize();
    });
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    $route(to) {
      if (to.name === "Home") {
        setTimeout(() => {
          document.body.classList.add("snap");
        }, 10);
      } else {
        document.body.classList.remove("snap");
      }
    },
  },

  methods: {
    onResize() {
      this.setViewportHeight();
    },
    setViewportHeight() {
      document.documentElement.style.setProperty(
        "--app-height",
        window.innerHeight + "px"
      );
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style lang="scss">
// * {
//   border: solid 1px red;
// }
#main {
  background-color: $body_bg;
}
.page {
  //   padding: $base * 6 2.116402116vw;
  //   padding-top: $base * 9;
  //   padding-top: clamp(
  //     #{$base} * 6,
  //     calc(var(--app-height) * 0.07433808554),
  //     #{$base} * 11
  //   );
  padding: 0 $base * 2;
  padding-bottom: 0;
  @media (min-width: 1024px) {
    padding: 0 $base * 4;
  }
}
// footer {
//   height: 40vh;
//   width: 100%;
//   background: green;
// }
// .snap {
//   footer {
//     @media (min-width: 1024px) {
//       scroll-snap-align: end;
//     }
//   }
// }

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease 0.5s, transform 0.5s ease;
}

.v-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.v-enter-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
