<template>
  <router-link
    :to="to"
    class="filter-btn"
    :class="{
      selected: selected,
      disabled: disabled,
      'no-text': !hasTextSlot,
      'no-icon': !hasIconSlot,
    }"
  >
    <slot name="icon" v-if="hasIconSlot"></slot>
    <span class="filter-btn--text" v-if="hasTextSlot">
      <slot name="text"></slot>
    </span>
  </router-link>
</template>

<script>
export default {
  name: "FilterButton",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasTextSlot() {
      return !!this.$slots.text;
    },
    hasIconSlot() {
      return !!this.$slots.icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-btn {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: $base * 0.5;
  border-radius: 500px;
  border: 2px solid $body_color;
  padding: $base * 0.2 $base * 1;
  padding-left: $base * 0.5;
  padding-bottom: $base * 0;
  background: transparent;
  color: $body_color;
  font-size: $base * 2;
  line-height: $base * 3;
  font-family: $font_family_alt;
  text-transform: uppercase;
  font-weight: normal;
  transition: all ease 0.1s;
  width: 100%;
  height: $base * 4;

  @media (min-width: 1024px) {
    width: auto;
    font-size: $base * 2.625;
    line-height: 1;
    padding: $base * 0.5 $base * 1.75;
    padding-left: $base * 1;
    height: $base * 5;
  }

  &--text {
    padding-top: $base * 0.1;

    @media (min-width: 1024px) {
      padding-top: $base * 0.4;
    }
  }

  &.selected {
    transition: all ease 0.3s;
    background: rgba($corporate_color_rgb, 0.7);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.no-text {
    padding: $base * 1;
    width: auto;
    @media (min-width: 1024px) {
      width: auto;
      padding: $base * 1;
    }
  }
  &.no-icon {
    padding: $base * 0.2 $base * 1;

    @media (min-width: 1024px) {
      padding: $base * 0.5 $base * 1.75;
    }
  }
}
</style>
