<template>
  <div class="page-content page-programs-single--content" v-if="parsedPost">
    <!-- <pre v-if="parsedPost" style="grid-column: 1 / span 12">{{
      parsedPost
    }}</pre> -->
    <breadcrumbs-component :breadcrumbs="breadcrumbs"></breadcrumbs-component>
    <div class="programs-single--content--header">
      <h1
        class="programs-single--content--header__title"
        v-if="this.$root.$i18n.locale === 'es'"
      >
        {{ parsedPost.title.es }}
      </h1>
      <h1
        class="programs-single--content--header__title"
        v-if="this.$root.$i18n.locale === 'ca'"
      >
        {{ parsedPost.title.cat ? parsedPost.title.cat : parsedPost.title.es }}
      </h1>
      <h1
        class="programs-single--content--header__title"
        v-if="this.$root.$i18n.locale === 'en'"
      >
        {{ parsedPost.title.en ? parsedPost.title.en : parsedPost.title.es }}
      </h1>
      <a
        class="programs-single--content--header__link"
        :href="this.parsedPost.programUrl"
        target="_blank"
        ><v-icon size="x-small">mdi-link-variant</v-icon>
        {{ $t("official_program") }}</a
      >
    </div>
    <div class="programs-single--content--wrapper">
      <div class="programs-single--content--wrapper--text">
        <div
          class="programs-single--content--wrapper--text__content"
          v-if="this.$root.$i18n.locale === 'es'"
          v-html="parsedPost.content.es"
        ></div>
        <div
          class="programs-single--content--wrapper--text__content"
          v-if="this.$root.$i18n.locale === 'ca'"
          v-html="
            parsedPost.content.cat
              ? parsedPost.content.cat
              : parsedPost.content.es
          "
        ></div>
        <div
          class="programs-single--content--wrapper--text__content"
          v-if="this.$root.$i18n.locale === 'en'"
          v-html="
            parsedPost.content.en
              ? parsedPost.content.en
              : parsedPost.content.es
          "
        ></div>
      </div>
      <div
        class="programs-single--content--wrapper--aside"
        v-if="!this.$vuetify.display.mobile"
      >
        <div class="programs-single--content--wrapper--aside__image">
          <img :src="parsedPost.image.src" :alt="parsedPost.title.cat" />
        </div>
      </div>
    </div>
    <div
      class="programs-single--content--units"
      v-if="
        parsedPost.units.es.length > 0 ||
        parsedPost.units.cat.length > 0 ||
        parsedPost.units.en.length > 0
      "
    >
      <h2 class="programs-single--content--units__title">
        {{ $t("our") }} <span>{{ $t("units") }}</span>
      </h2>
      <div class="programs-single--content--units__list">
        <template v-if="this.$root.$i18n.locale === 'es'">
          <unit-program-card
            v-for="(unit, index) in parsedPost.units.es"
            :key="index"
            :unit="unit"
          ></unit-program-card>
        </template>
        <template v-if="this.$root.$i18n.locale === 'ca'">
          <unit-program-card
            v-for="(unit, index) in parsedPost.units.cat
              ? parsedPost.units.cat
              : parsedPost.units.es"
            :key="index"
            :unit="unit"
          ></unit-program-card>
        </template>
        <template v-if="this.$root.$i18n.locale === 'en'">
          <unit-program-card
            v-for="(unit, index) in parsedPost.units.en
              ? parsedPost.units.en
              : parsedPost.units.es"
            :key="index"
            :unit="unit"
          ></unit-program-card>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import ImagesService from "@/services/images.service.js";
import UnitProgramCard from "@/components/units/UnitProgramCard.vue";
import BreadcrumbsComponent from "@/components/layout/BreadcrumbsComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "SingleProgramView",
  components: {
    UnitProgramCard,
    BreadcrumbsComponent,
  },
  data() {
    return {
      post: null,
      parsedPost: null,
      isLoading: true,
    };
  },
  async created() {
    await this.fetchPost();
    if (this.parsedPost) {
      if (this.$root.$i18n.locale === "es") {
        document.title = "DPA UPV | " + this.parsedPost.title.es;
      } else if (this.$root.$i18n.locale === "ca") {
        document.title =
          "DPA UPV | " + this.parsedPost.title.cat
            ? this.parsedPost.title.cat
            : this.parsedPost.title.es;
      } else if (this.$root.$i18n.locale === "en") {
        document.title =
          "DPA UPV | " + this.parsedPost.title.en
            ? this.parsedPost.title.en
            : this.parsedPost.title.es;
      }
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: {
            es: this.$t("programs"),
            en: this.$t("programs"),
            ca: this.$t("programs"),
          },
          to: "/programs",
        },
        {
          text: {
            es: this.parsedPost.typology ? this.parsedPost.typology.es : "",
            en: this.parsedPost.typology ? this.parsedPost.typology.en : "",
            ca: this.parsedPost.typology ? this.parsedPost.typology.ca : "",
          },
          to: "/programs",
        },
        {
          text: {
            es: this.parsedPost.title.es ? this.parsedPost.title.es : "",
            en: this.parsedPost.title.en ? this.parsedPost.title.en : "",
            ca: this.parsedPost.title.cat ? this.parsedPost.title.cat : "",
          },
          to: "/programs",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),

    getImageUrl(imagen, size) {
      if (imagen) {
        return ImagesService.getImageUrl(imagen, size);
      } else {
        return null;
      }
    },

    async fetchPost() {
      this.startLoading(); // Inicia el estado de carga antes de la llamada a la API

      this.parsedPost = await PostsService.getSingleProgram(
        this.$route.params.id
      )
        .then(async (res) => {
          const program = {
            typology: res.acf.typology,
            title: {
              es: res.acf.program_title.title_es,
              en: res.acf.program_title.title_en,
              cat: res.acf.program_title.title_cat,
            },
            programUrl: res.acf.program_url,
            image: {
              src: this.getImageUrl(res.acf.image, "large"),
              alt: res.title.rendered,
            },
            content: {
              es: res.acf.content.content_es,
              en: res.acf.content.content_en,
              cat: res.acf.content.content_cat,
            },
            units: {
              es: [],
              en: [],
              cat: [],
            },
          };

          const unitIds = res.acf.units;
          if (unitIds) {
            // map res
            for (let unitId of unitIds) {
              const unit = await PostsService.getUnitById(unitId.ID);
              // console.log(unit);
              program.units.es.push({
                id: unitId.ID,
                slug: unit.slug,
                acronym: unit.title.rendered,
                name: unit.acf.unit_name.name_es,
                cover_image: {
                  src: this.getImageUrl(unit.acf.cover_image, "large"),
                  alt: unit.acf.unit_name.name_es,
                },
              });
              program.units.en.push({
                id: unitId.ID,
                slug: unit.slug,
                acronym: unit.title.rendered,
                name: unit.acf.unit_name.name_en,
                cover_image: {
                  src: this.getImageUrl(unit.acf.cover_image, "large"),
                  alt: unit.acf.unit_name.name_en,
                },
              });
              program.units.cat.push({
                id: unitId.ID,
                slug: unit.slug,
                acronym: unit.title.rendered,
                name: unit.acf.unit_name.name_cat,
                cover_image: {
                  src: this.getImageUrl(unit.acf.cover_image, "large"),
                  alt: unit.acf.unit_name.name_cat,
                },
              });
            }
          }

          // Get typology data
          const typologyId = res.acf.typology;
          const typologyData = await PostsService.getTaxonomyFields(
            "studies",
            typologyId
          );
          program.typology = {
            ca: typologyData.acf.translations.name_ca,
            es: typologyData.acf.translations.name_es,
            en: typologyData.acf.translations.name_en,
          };

          return program;
        })
        .finally(() => {
          this.isLoading = false;
          this.stopLoading(); // Finaliza el estado de carga después de recibir la respuesta o en caso de error
        });
      // map res
    },
  },
};
</script>

<style lang="scss" scoped>
.page-programs-single--content {
  padding: 0 $base * 4;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.07433808554),
    #{$base} * 11
  );
  display: flex;
  flex-direction: column;
  margin-bottom: $base * 4;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    column-gap: $base * 3;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .programs-single--content--header {
    grid-column: 2 / span 7;
    margin-bottom: $base * 5;
    padding: 0 $base * 2;

    @media (min-width: 1024px) {
      padding: 0;
    }

    &__title {
      font-family: $font_family_alt;
      font-size: $base * 4;
      line-height: $base * 4.5;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: $base;
    }

    &__link {
      font-size: $base * 2;
      line-height: $base * 3;
      text-transform: uppercase;
    }
  }

  .programs-single--content--wrapper {
    grid-column: 1 / span 12;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: $base * 3;
      margin-bottom: calc(var(--app-height) * 0.2077393075);
    }

    .programs-single--content--wrapper--text {
      grid-column: 2 / span 6;
      margin-bottom: calc(var(--app-height) * 0.1077393075);
      padding: 0 $base * 2;

      @media (min-width: 1024px) {
        padding: 0;
      }
    }

    .programs-single--content--wrapper--aside {
      width: 100%;
      grid-column: 9 / span 3;

      &__image {
        width: 100%;
        height: 100%;
        max-height: calc(var(--app-height) * 0.9460285132);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .programs-single--content--units {
    grid-column: 1 / span 12;
    height: 100%;
    width: calc(100% + #{$base * 4});
    margin-left: -$base * 2;
    padding-bottom: $base * 5;

    @media (min-width: 1024px) {
      background: $body_color;
      padding-bottom: calc(var(--app-height) * 0.2454175153);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: $base * 3;
    }

    &__title {
      grid-column: 2 / span 10;
      font-size: $base * 5;
      line-height: $base * 5.5;
      font-family: $font_family_alt;
      font-weight: 400;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      margin-bottom: $base * 3;
      padding-left: $base * 2;
      color: $body_color;

      @media (min-width: 1024px) {
        padding-left: 0;
        font-size: $base * 8.5;
        line-height: $base * 9;
        color: $body_bg;
        mix-blend-mode: difference;
        margin-top: -$base * 8;
        margin-bottom: $base * 8;
      }
    }

    &__list {
      grid-column: 2 / span 10;
      display: flex;
      flex-direction: column;
      gap: $base * 2;

      @media (min-width: 1024px) {
        gap: $base * 3;
      }
    }
  }
}
</style>
<style lang="scss">
.programs-single--content--wrapper--text {
  &__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font_family_alt;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}
</style>
