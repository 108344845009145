<template>
  <div class="mobile-menu" :class="[menuOpen ? 'open' : 'close']">
    <div class="mobile-menu--top-bar">
      <div class="mobile-menu--top-bar--logo">
        <top-bar-logo dark />
      </div>
      <div class="mobile-menu--top-bar--burger">
        <div
          class="mobile-menu--top-bar--burger--icon"
          :class="[menuOpen ? 'open' : 'close']"
          @click="toggleMenu"
        >
          <div class="mobile-menu--top-bar--burger--icon__line" />
          <div class="mobile-menu--top-bar--burger--icon__line" />
          <div class="mobile-menu--top-bar--burger--icon__line" />
        </div>
      </div>
      <div class="mobile-menu--top-bar-mobile--archive">
        <a
          class="mobile-menu--top-bar-mobile--archive__button"
          @click="toggleArchive"
        >
          {{ $t("archive") }}
        </a>
        <div
          class="mobile-menu--top-bar-mobile--archive__overlay"
          :class="[archiveOpen ? 'open' : 'close']"
        >
          <div class="mobile-menu--top-bar-mobile--archive__overlay__inner">
            <a
              href="https://archivedpa.webs.upv.es/archive/"
              target="_blank"
              class="mobile-menu--top-bar-mobile--archive__overlay__inner__link"
              @click="toggleArchive"
            >
              <p>Visita <span>↗</span> nuestro archivo de proyectos</p>
            </a>
            <a
              class="mobile-menu--top-bar-mobile--archive__overlay__inner__close"
              @click="toggleArchive"
            >
              <img
                :src="require('@/assets/svgs/icon-close-white.svg')"
                alt="close"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu--content">
      <div class="mobile-menu--content__inner">
        <div class="mobile-menu--content__inner__links">
          <div class="links--group">
            <router-link to="/people" class="menu--item" @click="toggleMenu">
              {{ $t("people") }}
            </router-link>
            <ul class="submenu--items">
              <li>
                <router-link
                  :to="{
                    name: 'People',
                    query: {
                      taxonomyId: 26,
                    },
                  }"
                  @click="toggleMenu"
                >
                  {{ $t("staff") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'People',
                    query: {
                      taxonomyId: 24,
                    },
                  }"
                  @click="toggleMenu"
                >
                  {{ $t("students") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'People',
                    query: {
                      taxonomyId: 25,
                    },
                  }"
                  @click="toggleMenu"
                >
                  {{ $t("faculty") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="links--group">
            <router-link to="/events" class="menu--item" @click="toggleMenu">
              {{ $t("daily") }}
            </router-link>
            <ul class="submenu--items">
              <li>
                <router-link to="/events/search">
                  {{ $t("calendar.search") }}
                </router-link>
              </li>
              <li>
                <router-link to="/events?view=weekly">
                  {{ $t("calendar.week") }}
                </router-link>
              </li>
              <li>
                <router-link to="/events?view=monthly">
                  {{ $t("calendar.month") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="links--group">
            <router-link to="/programs" class="menu--item" @click="toggleMenu">
              {{ $t("programs") }}
            </router-link>
            <ul class="submenu--items">
              <li>
                <router-link to="/programs" @click="testProgramLink(0)">
                  {{ $t("bachelor") }}
                </router-link>
              </li>
              <li>
                <router-link to="/programs" @click="testProgramLink(1)">
                  {{ $t("master") }}
                </router-link>
              </li>
              <li>
                <router-link to="/programs" @click="testProgramLink(2)">
                  {{ $t("research") }}
                </router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="links--group">
            <router-link to="/units" class="menu--item" @click="toggleMenu">
              {{ $t("units") }}
            </router-link>
          </div> -->
          <div class="links--group">
            <router-link to="/structure" class="menu--item" @click="toggleMenu">
              {{ $t("structure") }}
            </router-link>
          </div>
        </div>
        <div class="mobile-menu--content__inner__aside">
          <a
            href="https://www.instagram.com/etsa.upv/"
            target="_blank"
            rel="noopener noreferrer"
            class="mobile-menu--content__aside__link"
            @click="toggleMenu"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.6663 6.66675H13.3329C9.65103 6.66675 6.66626 9.65152 6.66626 13.3334V26.6667C6.66626 30.3486 9.65103 33.3334 13.3329 33.3334H26.6663C30.3482 33.3334 33.3329 30.3486 33.3329 26.6667V13.3334C33.3329 9.65152 30.3482 6.66675 26.6663 6.66675Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.9995 25C22.7609 25 24.9995 22.7614 24.9995 20C24.9995 17.2386 22.7609 15 19.9995 15C17.2381 15 14.9995 17.2386 14.9995 20C14.9995 22.7614 17.2381 25 19.9995 25Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M27.4995 12.5V12.5013"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <div class="mobile-menu--content__inner__aside__lang-selector">
            <lang-selector />
          </div>
        </div>
        <div class="mobile-menu--content__inner__logos">
          <a
            href="https://www.upv.es"
            target="_blank"
            rel="noopener noreferrer"
            class="mobile-menu--content__inner__logos__item"
            @click="toggleMenu"
          >
            <logo-upv-svg />
          </a>
          <a
            href="http://www.upv.es/entidades/ETSA/"
            target="_blank"
            rel="noopener noreferrer"
            class="mobile-menu--content__inner__logos__item"
            @click="toggleMenu"
          >
            <logo-etsa-svg />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBarLogo from "@/components/TopBarLogo.vue";
import LogoUpvSvg from "@/components/svgs/LogoUpvSvg.vue";
import LogoEtsaSvg from "@/components/svgs/LogoEtsaSvg.vue";
import LangSelector from "@/components/LangSelector.vue";
export default {
  name: "MobileMenu",
  components: {
    TopBarLogo,
    LogoUpvSvg,
    LogoEtsaSvg,
    LangSelector,
  },
  data() {
    return {
      menuOpen: false,
      archiveOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
      this.$emit("toggle", this.menuOpen);
    },
    toggleArchive() {
      this.archiveOpen = !this.archiveOpen;
    },
    testProgramLink(index) {
      this.menuOpen = !this.menuOpen;
      this.$store.dispatch("setActiveSlideIndex", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--app-height);
  background-color: rgba($body_color_rgb, 1);
  z-index: 400;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  &.close {
    opacity: 0;
    visibility: hidden;
  }

  .mobile-menu--top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $base $base * 2;
    position: relative;

    .mobile-menu--top-bar--logo {
      mix-blend-mode: difference;
      justify-content: flex-start;
    }
    .mobile-menu--top-bar--burger {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $base * 5;
      height: $base * 4;
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .mobile-menu--top-bar--burger--icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        max-height: $base * 4;
        position: relative;
        overflow: hidden;
        .mobile-menu--top-bar--burger--icon__line {
          width: 100%;
          height: 4px;
          background-color: $body_bg;
          transition: all 0.3s ease-out;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 300;

          &:nth-child(1) {
            top: 20%;
          }

          &:nth-child(3) {
            top: 80%;
          }
        }
        &.open {
          .mobile-menu--top-bar--burger--icon__line {
            &:nth-child(1) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-child(2) {
              position: absolute;
              top: 50%;
              transform: rotate(0) translate(100%, -50%);
            }
            &:nth-child(3) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      }
    }
    .mobile-menu--top-bar-mobile--archive {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      &__button {
        font-size: $base * 2.5;
        line-height: $base * 3.4;
        font-weight: normal;
        font-family: $font_family_alt;
        background: $body_color;
        color: $body_bg;
        border-radius: 500px;
        text-transform: uppercase;
        padding: $base * 0.5 $base;
        height: $base * 4;
      }
      &__overlay {
        position: fixed;
        top: clamp(
          #{$base} * 6,
          calc(var(--app-height) * 0.07433808554),
          #{$base} * 11
        );
        right: 0;
        z-index: 300;
        background: $body_color;
        max-width: $base * 21.75;
        transition: all 0.3s ease-out;
        &.open {
          transform: translateX(0);
        }
        &.close {
          transform: translateX(100%);
        }

        &__inner {
          position: relative;
          padding: $base * 2;
          &__link {
            color: $corporate_color;
            font-size: $base * 2.5;
            line-height: $base * 3;
            font-family: $font_family_alt;
            text-transform: uppercase;
            p {
              margin-bottom: 0;
              span {
                font-size: $base * 3.3;
                line-height: $base * 3;
                font-family: $font_family_alt;
                text-transform: uppercase;
              }
            }
          }
          &__close {
            position: absolute;
            top: $base;
            right: $base * 0.5;
          }
        }
      }
    }
  }
  .mobile-menu--content {
    padding: $base * 4;
    height: 100%;
    &__inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 100%;
      &__links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: $base * 4;
        grid-column: 1 / span 1;

        a {
          color: $body_bg;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        .links--group {
          display: flex;
          flex-direction: column;
          gap: $base;
          .menu--item {
            font-size: $base * 3;
            font-family: $font_family_base;
            //   letter-spacing: -0.02em;
            line-height: 1;
            font-weight: 800;
            text-transform: uppercase;
            text-align: left;
          }
          .submenu--items {
            display: flex;
            flex-direction: column;
            gap: $base * 0.5;
            text-align: left;
            li a {
              font-size: $base * 2;
              font-family: $font_family_alt;
              line-height: 1;
              text-transform: uppercase;
            }
          }
        }
      }
      &__aside {
        grid-column: 2 / span 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      &__logos {
        display: flex;
        flex-wrap: nowrap;
        gap: $base * 6;
        width: 100%;
        padding: 0;
        grid-column: 1 / span 2;
        margin-top: auto;

        &__item {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 100%;
            height: auto;
            object-fit: cover;
            g {
              path {
                fill: $body_bg;
                transition: all 0.3s ease;
              }
            }
            path {
              fill: $body_bg;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
}
</style>
