import { createStore } from "vuex";

export default createStore({
  state: {
    activeSlideIndex: 0,
    activeTheme: "light-mode",
    isDailyOpen: false,
    loading: false, // agregar el estado "loading" al store
  },
  mutations: {
    setActiveSlideIndex(state, value) {
      state.activeSlideIndex = value;
    },
    setActiveTheme(state, value) {
      state.activeTheme = value;
    },
    setLoading(state, loading) {
      // agregar la mutación "setLoading" al store
      state.loading = loading;
    },
  },
  actions: {
    setActiveSlideIndex({ commit }, index) {
      commit("setActiveSlideIndex", index);
    },
    setActiveTheme({ commit }, theme) {
      commit("setActiveTheme", theme);
    },
    startLoading({ commit }) {
      commit("setLoading", true); // actualizar el estado "loading" a true
    },
    stopLoading({ commit }) {
      commit("setLoading", false); // actualizar el estado "loading" a false
    },
  },
  getters: {
    isLoading: (state) => state.loading, // agregar el getter "isLoading" al store
  },
  modules: {},
});
