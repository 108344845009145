<template>
  <section class="section--educational-programs">
    <h2 class="section-educational-programs--title">
      <div class="section-educational-programs--title__main">
        {{ $t("educational") }}
      </div>
      <!-- <div class="section-educational-programs--title__secondary">
        <span
          v-if="!this.$vuetify.display.mobile"
          class="section-educational-programs--title__secondary__left"
        >
          {{ $t("architectural") }}</span
        >
        <span class="section-educational-programs--title__secondary__right">{{
          $t("programs")
        }}</span>
      </div> -->
    </h2>
    <!-- <pre>{{ bachelorPrograms + masterPrograms + researchPrograms }}</pre> -->
    <div
      class="section--educational-programs--slider"
      v-if="
        loadingBachelors ||
        loadingMasters ||
        loadingResearch ||
        loadingTypologies
      "
    >
      <ul class="swiper-menu">
        <li v-for="n in 3" :key="n" class="swiper-menu--item">
          <div class="skeleton-loader"></div>
        </li>
      </ul>
      <skeleton-slider></skeleton-slider>
    </div>
    <div class="section--educational-programs--slider" v-else>
      <ul
        class="swiper-menu"
        v-if="
          taxonomies.length > 0 &&
          bachelorPrograms.length > 0 &&
          masterPrograms.length > 0 &&
          researchPrograms.length > 0
        "
      >
        <li
          v-for="taxonomy in taxonomies"
          :key="taxonomy.id"
          class="swiper-menu--item"
          :class="{
            'swiper-menu--item__active':
              taxonomy.index === this.$store.state.activeSlideIndex,
          }"
        >
          <a
            class="swiper-menu--item__hover"
            @click="toggleActiveSlider(taxonomy.index)"
          >
            <div
              v-if="!this.$vuetify.display.mobile"
              class="swiper-menu--item__img"
            >
              <img :src="taxonomy.image" />
            </div>
            <div
              v-if="!this.$vuetify.display.mobile"
              class="swiper-menu--item__gradient"
            />
            <div
              class="swiper-menu--item__text"
              v-if="this.$root.$i18n.locale === 'es'"
            >
              {{ taxonomy.name.es }}
            </div>
            <div
              class="swiper-menu--item__text"
              v-if="this.$root.$i18n.locale === 'en'"
            >
              {{ taxonomy.name.en ? taxonomy.name.en : taxonomy.name.es }}
            </div>
            <div
              class="swiper-menu--item__text"
              v-if="this.$root.$i18n.locale === 'ca'"
            >
              {{ taxonomy.name.ca ? taxonomy.name.ca : taxonomy.name.es }}
            </div>
          </a>
        </li>
      </ul>

      <template v-if="bachelorPrograms && masterPrograms && researchPrograms">
        <educational-slider
          v-if="this.$store.state.activeSlideIndex == 0 && bachelorPrograms"
          :slides="bachelorPrograms"
        />

        <educational-slider
          v-if="this.$store.state.activeSlideIndex == 1 && masterPrograms"
          :slides="masterPrograms"
        />

        <educational-slider
          v-if="this.$store.state.activeSlideIndex == 2 && researchPrograms"
          :slides="researchPrograms"
        />
      </template>
    </div>
  </section>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import ImagesService from "@/services/images.service.js";
import EducationalSlider from "@/components/sliders/EducationalSlider.vue";
import SkeletonSlider from "@/components/sliders/SkeletonSlider.vue";

import sanitizeHtml from "sanitize-html";

export default {
  name: "EdicationalSlider",
  components: {
    EducationalSlider,
    SkeletonSlider,
  },
  data() {
    return {
      loadingTypologies: true,
      loadingBachelors: true,
      loadingMasters: true,
      loadingResearch: true,
      taxonomy: "studies",
      taxonomies: [],
      bachelorTaxonomyID: 27,
      bachelorPrograms: [],
      masterTaxonomyID: 28,
      masterPrograms: [],
      researchTaxonomyID: 29,
      researchPrograms: [],
      cathedraTaxonomyID: 30,
      cathedraPrograms: [],
      res: [],
      activeIndex: 0,
    };
  },
  created() {
    this.fetchTypologies();
    this.fetchBachelors();
    this.fetchMasters();
    this.fetchResearch();
  },
  mounted() {
    this.$store.commit("setActiveSlideIndex", 0);
  },
  methods: {
    async fetchTypologies() {
      this.loadingTypologies = true;
      this.taxonomies = await PostsService.getAllCustomTaxonomies(
        this.taxonomy
      ).then(async (res) => {
        const taxonomies = await Promise.all(
          res.map(async (taxonomy, index) => {
            return {
              image: taxonomy.acf.taxonomy_image
                ? taxonomy.acf.taxonomy_image.sizes.large
                : null,
              name: {
                es: taxonomy.acf.translations.name_es,
                ca: taxonomy.acf.translations.name_ca,
                en: taxonomy.acf.translations.name_en,
              },
              id: taxonomy.id,
              index: index,
            };
          })
        );
        this.$nextTick(function () {
          this.loadingTypologies = false;
        });
        return taxonomies;
      });
    },
    async fetchBachelors() {
      this.loadingBachelors = true;
      // const taxonomyFields = await PostsService.getTaxonomyFields(
      //   this.taxonomy,
      //   this.bachelorTaxonomyID
      // );
      this.bachelorPrograms = await PostsService.getProgramsByTaxonomy(
        this.taxonomy,
        this.bachelorTaxonomyID
      )
        .then(async (res) => {
          return Promise.all(
            res.map(async (post) => {
              // const taxonomyImageId = post.acf.typology.ID;
              const imagenFull = await PostsService.getImage(post.acf.image);
              const imagenLarge = await PostsService.getImage(
                post.acf.image,
                "large"
              );
              const excerptEs = sanitizeHtml(post.acf.content.content_es, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptEn = sanitizeHtml(post.acf.content.content_en, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptCa = sanitizeHtml(post.acf.content.content_cat, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptLimit = this.$vuetify.display.mobile ? 1 : 0.4;
              return {
                title: {
                  es:
                    (post.acf.program_title &&
                      post.acf.program_title.title_es) ||
                    "",
                  en:
                    (post.acf.program_title &&
                      post.acf.program_title.title_en) ||
                    "",
                  ca:
                    (post.acf.program_title &&
                      post.acf.program_title.title_cat) ||
                    "",
                },
                content: {
                  es: (post.acf.content && post.acf.content.content_es) || "",
                  en: (post.acf.content && post.acf.content.content_en) || "",
                  ca: (post.acf.content && post.acf.content.content_cat) || "",
                },
                excerpt: {
                  es:
                    excerptEs &&
                    excerptEs.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptEs.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptEs || "",
                  en:
                    excerptEn &&
                    excerptEn.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptEn.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptEn || "",
                  ca:
                    excerptCa &&
                    excerptCa.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptCa.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptCa || "",
                },
                image: {
                  full: (imagenFull && imagenFull.source_url) || "",
                  large: (imagenLarge && imagenLarge.source_url) || "",
                },
                slug: post.slug || "",
                id: post.id || "",
                // otros campos
              };
            })
          );
        })
        .finally(() => {
          this.loadingBachelors = false;
        });
    },
    async fetchMasters() {
      this.loadingMasters = true;
      this.masterPrograms = await PostsService.getProgramsByTaxonomy(
        this.taxonomy,
        this.masterTaxonomyID
      )
        .then(async (res) => {
          return Promise.all(
            res.map(async (post) => {
              const imagenFull = await PostsService.getImage(post.acf.image);
              const imagenLarge = await PostsService.getImage(
                post.acf.image,
                "large"
              );
              const excerptEs = sanitizeHtml(post.acf.content.content_es, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptEn = sanitizeHtml(post.acf.content.content_en, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptCa = sanitizeHtml(post.acf.content.content_cat, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptLimit = this.$vuetify.display.mobile ? 1 : 0.4;
              return {
                title: {
                  es:
                    (post.acf.program_title &&
                      post.acf.program_title.title_es) ||
                    "",
                  en:
                    (post.acf.program_title &&
                      post.acf.program_title.title_en) ||
                    "",
                  ca:
                    (post.acf.program_title &&
                      post.acf.program_title.title_cat) ||
                    "",
                },
                content: {
                  es: (post.acf.content && post.acf.content.content_es) || "",
                  en: (post.acf.content && post.acf.content.content_en) || "",
                  ca: (post.acf.content && post.acf.content.content_cat) || "",
                },
                excerpt: {
                  es:
                    excerptEs &&
                    excerptEs.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptEs.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptEs || "",
                  en:
                    excerptEn &&
                    excerptEn.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptEn.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptEn || "",
                  ca:
                    excerptCa &&
                    excerptCa.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptCa.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptCa || "",
                },
                image: {
                  full: (imagenFull && imagenFull.source_url) || "",
                  large: (imagenLarge && imagenLarge.source_url) || "",
                },
                slug: post.slug || "",
                id: post.id || "",
                // otros campos
              };
            })
          );
        })
        .finally(() => {
          this.loadingMasters = false;
        });
    },
    async fetchResearch() {
      this.loadingResearch = true;
      this.researchPrograms = await PostsService.getProgramsByTaxonomy(
        this.taxonomy,
        this.researchTaxonomyID
      )
        .then(async (res) => {
          return Promise.all(
            res.map(async (post) => {
              const imagenFull = await PostsService.getImage(post.acf.image);
              const imagenLarge = await PostsService.getImage(
                post.acf.image,
                "large"
              );
              const excerptEs = sanitizeHtml(post.acf.content.content_es, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptEn = sanitizeHtml(post.acf.content.content_en, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptCa = sanitizeHtml(post.acf.content.content_cat, {
                allowedTags: [
                  "p",
                  "a",
                  "strong",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "h6",
                  "ol",
                  "ul",
                  "li",
                ],
                allowedAttributes: [],
              });
              const excerptLimit = this.$vuetify.display.mobile ? 1 : 0.4;
              return {
                title: {
                  es:
                    (post.acf.program_title &&
                      post.acf.program_title.title_es) ||
                    "",
                  en:
                    (post.acf.program_title &&
                      post.acf.program_title.title_en) ||
                    "",
                  ca:
                    (post.acf.program_title &&
                      post.acf.program_title.title_cat) ||
                    "",
                },
                content: {
                  es: (post.acf.content && post.acf.content.content_es) || "",
                  en: (post.acf.content && post.acf.content.content_en) || "",
                  ca: (post.acf.content && post.acf.content.content_cat) || "",
                },
                excerpt: {
                  es:
                    excerptEs &&
                    excerptEs.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptEs.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptEs || "",
                  en:
                    excerptEn &&
                    excerptEn.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptEn.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptEn || "",
                  ca:
                    excerptCa &&
                    excerptCa.length >
                      (this.$vuetify.display.height * excerptLimit || 0)
                      ? excerptCa.substring(
                          0,
                          this.$vuetify.display.height * excerptLimit
                        ) + "..."
                      : excerptCa || "",
                },
                image: {
                  full: (imagenFull && imagenFull.source_url) || "",
                  large: (imagenLarge && imagenLarge.source_url) || "",
                },
                slug: post.slug || "",
                id: post.id || "",
                // otros campos
              };
            })
          );
        })
        .finally(() => {
          this.loadingResearch = false;
        });
    },
    toggleActiveSlider(index) {
      this.$store.dispatch("setActiveSlideIndex", index);
    },
    getImageUrl(imagen, size) {
      return ImagesService.getImageUrl(imagen, size);
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }
section.section--educational-programs {
  // height: var(--app-height);
  padding-top: $base * 9;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.06433808554),
    #{$base} * 11
  );
  padding-bottom: $base * 4;
  display: flex;
  flex-direction: column;
  height: var(--app-height);

  @media (min-width: 1024px) {
    scroll-snap-align: start;
  }

  .section-educational-programs--title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $base * 2;

    @media (min-width: 1024px) {
      align-items: center;
      margin-bottom: $base * 3;
    }

    &__main {
      margin: 0;
      font-size: $base * 4;
      line-height: 1;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      display: block;
      flex-grow: 1;
      color: $body_color;

      @media (min-width: 1024px) {
        font-size: clamp(#{$base * 10}, 9.25925925935vw, #{$base * 20});
        margin-bottom: -$base * 1;
      }
    }

    &__secondary {
      min-width: $base * 76;
      width: 70.7vw;
      max-width: $base * 151.999999998;
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      font-weight: 300;
      font-size: $base * 4;
      text-transform: uppercase;
      color: $body_color;

      @media (min-width: 1024px) {
        font-size: clamp(#{$base * 3}, 2.1164021164vw, #{$base * 5});
        font-weight: 400;
      }
    }
  }
}

.section--educational-programs--slider {
  // width: calc(100% + #{$base * 8});
  // margin-left: -$base * 4;
  width: 100%;
  // height: calc(var(--app-height) * 0.610794297);
  height: 100%;
  // min-height: calc(var(--app-height) - $base * 6);
  // max-height: calc(var(--app-height) - $base * 11);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $base * 2;

  @media (min-width: 1024px) {
    gap: $base * 4;
  }

  .swiper-menu {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    gap: $base * 2;
    margin: 0;
    z-index: 1;

    @media (min-width: 1024px) {
      gap: $base * 4;
    }

    .swiper-menu--item {
      padding: 0;
      margin: 0;
      text-align: center;
      height: auto;
      min-height: $base * 3;
      position: relative;
      flex-grow: 1;
      @media (min-width: 1024px) {
        height: $base * 12;
      }
      & .skeleton-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba($body_color_rgb, 0.7),
          rgba($body_color_rgb, 0.9),
          rgba($body_color_rgb, 0.7)
        );
        background-size: 200%;
        animation: loader 1s infinite reverse;
        z-index: 1;
        @keyframes loader {
          from {
            background-position: -100% 0;
          }
          to {
            background-position: 100% 0;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background: $body_color;
        z-index: -1;
        transition: all 0.1s ease;
        opacity: 0.3;

        @media (min-width: 1024px) {
          width: 0%;
          height: calc(var(--app-height) * 0.008146639511);
          bottom: calc(var(--app-height) * -0.008146639511);
          opacity: 1;
        }
      }

      &__active {
        &::after {
          width: 100%;
          right: auto;
          left: 0;
          opacity: 1;
          transition: all 0.3s ease;
        }
      }

      a.swiper-menu--item__hover {
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;
      }

      a.swiper-menu--item__hover:hover {
        cursor: pointer;

        .swiper-menu--item__gradient {
          max-width: 60%;
        }
      }

      &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
        display: flex;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          90deg,
          rgba($body_color_rgb, 0.7) 0%,
          rgba($body_color_rgb, 0) 100%
        );
        width: 100%;
        max-width: 20%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        transition: max-width 0.3s ease;
      }

      &__text {
        color: $body_color;
        font-size: $base * 2;
        line-height: $base * 2;
        font-family: $font_family_alt;
        text-transform: uppercase;

        @media (min-width: 1024px) {
          color: $body_bg;
          font-size: $base * 3;
          line-height: $base * 3;
          position: absolute;
          left: 02.116402116vw;
          bottom: calc(var(--app-height) * 0.02647657841);
        }
      }
    }
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
