<template>
  <div class="week-events--swiper">
    <swiper
      :slides-per-view="2.5"
      :space-between="24"
      grabCursor
      :navigation="true"
      :modules="modules"
    >
      <template v-if="loading">
        <swiper-slide>
          <div class="week-events--swiper__loading">
            <h3 class="week-events--swiper__loading__title">
              {{ $t("calendar.loading") }}
            </h3>
          </div>
        </swiper-slide>
      </template>
      <template v-if="slides.length > 0">
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <week-event-desktop :event="slide"></week-event-desktop>
        </swiper-slide>
      </template>
      <template v-if="slides.length <= 0 && !loading">
        <swiper-slide>
          <div class="week-events--swiper__empty">
            <h3 class="week-events--swiper__empty__title">
              {{ $t("calendar.no_events") }}
            </h3>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import WeekEventDesktop from "@/components/daily/WeekEventDesktop.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    WeekEventDesktop,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation],
    };
  },
};
</script>
<style lang="scss" scoped>
// * {
//   border: 1px solid red;
// }
.week-events--swiper {
  width: 100%;
  height: 100%;
  .swiper-slide {
    position: relative;
    height: calc(var(--app-height) * 0.2);
    width: 100%;
    // min-width: 35vw;
    // max-width: 35vw;
    &--link {
      display: block;
      height: 100%;
      width: 100%;
      .swiper-slide--link__img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.week-events--swiper__empty,
.week-events--swiper__loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 9vw;
  &__title {
    font-size: $base * 2.25;
    font-weight: 400;
    color: var(--color-text);
  }
}
</style>
<!-- <style lang="scss">
.week-events--swiper {
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    overflow: hidden;
  }
  .swiper-button-prev {
    left: -$base * 3.5;
    @media (min-width: 1024px) {
      left: -$base * 4.5;
    }
    &:after {
      content: url(../../assets/svgs/icon-arrow-left.svg);
    }
  }
  .swiper-button-next {
    right: -$base * 3.5;
    @media (min-width: 1024px) {
      right: -$base * 4.5;
    }
    &:after {
      content: url(../../assets/svgs/icon-arrow-right.svg);
    }
  }
}
</style> -->
