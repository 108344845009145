import { createRouter, createWebHistory } from "vue-router";

import store from "@/store";

import HomeView from "../views/HomeView.vue";
import PeopleView from "../views/People/index.vue";
import StructureView from "../views/StructureView.vue";
import EventsView from "../views/Events/index.vue";
import EventsSearch from "../views/Events/EventsSearch.vue";
import EventSingle from "../views/Events/_id.vue";
import PeopleSingle from "../views/People/_id.vue";
import ProgramsView from "../views/Programs/index.vue";
import ProgramSingle from "../views/Programs/_id.vue";
import UnitSingle from "../views/Units/_id.vue";
import UnitsView from "../views/Units/index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },

  {
    path: "/people",
    name: "People",
    component: PeopleView,
    // mode: "abstract",
  },
  {
    path: "/people/:id/:slug",
    name: "PeopleSingle",
    component: PeopleSingle,
  },
  {
    path: "/events",
    name: "EventsCalendar",
    component: EventsView,
  },
  {
    path: "/events",
    name: "EventsCalendar",
    component: EventsView,
  },
  {
    path: "/events/search",
    name: "EventsSearch",
    component: EventsSearch,
  },
  {
    path: "/events/:id/:slug",
    name: "EventSingle",
    component: EventSingle,
  },
  {
    path: "/programs",
    name: "Programs",
    component: ProgramsView,
  },
  {
    path: "/structure",
    name: "Structure",
    component: StructureView,
  },
  {
    path: "/programs/:id/:slug",
    name: "ProgramSingle",
    component: ProgramSingle,
  },
  {
    path: "/units",
    name: "Units",
    component: UnitsView,
  },
  {
    path: "/units/:id/:slug",
    name: "UnitSingle",
    component: UnitSingle,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
});

router.beforeEach((to, from, next) => {
  // Aquí puedes llamar a tu acción de Vuex
  store.dispatch("setActiveTheme", "light-mode");
  const defaultTitle = "DPA UPV"; // Define el título por defecto
  document.title = to.meta.title || defaultTitle; // Establece el título de la página

  // llama a next() para continuar la navegación
  //alway scroll to top
  window.scrollTo(0, 0);
  document.body.scrollTo(0, 0);
  next();
});

export default router;
