<template>
  <swiper
    slides-per-view="auto"
    :space-between="32"
    :scrollbar="{ draggable: true }"
    :modules="modules"
    grabCursor
    v-if="slides.length > 0"
  >
    <swiper-slide v-for="slide in slides" :key="slide.image">
      <a class="swiper-slide__hover">
        <div class="swiper-slide__gradient" />

        <div class="swiper-slide__img">
          <img :src="slide.image.full" />
        </div>
        <div class="swiper-slide__copy">
          <h3
            class="swiper-slide__copy__title"
            v-if="this.$root.$i18n.locale === 'es'"
          >
            {{ slide.title.es }}
          </h3>
          <h3
            class="swiper-slide__copy__title"
            v-if="this.$root.$i18n.locale === 'en'"
          >
            {{ slide.title.en ? slide.title.en : slide.title.es }}
          </h3>
          <h3
            class="swiper-slide__copy__title"
            v-if="this.$root.$i18n.locale === 'ca'"
          >
            {{ slide.title.ca ? slide.title.ca : slide.title.es }}
          </h3>

          <div
            v-if="this.$root.$i18n.locale === 'es'"
            class="swiper-slide__copy__text"
            v-html="slide.excerpt.es"
          ></div>
          <div
            v-if="this.$root.$i18n.locale === 'en'"
            class="swiper-slide__copy__text"
            v-html="slide.excerpt.en ? slide.excerpt.en : slide.excerpt.es"
          ></div>
          <div
            v-if="this.$root.$i18n.locale === 'ca'"
            class="swiper-slide__copy__text"
            v-html="slide.excerpt.ca ? slide.excerpt.ca : slide.excerpt.es"
          ></div>
        </div>
        <div class="swiper-slide__button">
          <router-link
            :to="'/programs/' + slide.id + '/' + slide.slug + '/'"
            class="swiper-slide__button__link"
          >
            <span class="swiper-slide__button__link__text">
              {{ $t("see_more") }}
            </span>
          </router-link>
        </div>
      </a>
    </swiper-slide>
  </swiper>
</template>
<script>
// import Swiper core and required modules
import { Scrollbar } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  mounted() {},
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   // console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Scrollbar],
    };
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  overflow: visible;
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    // gap: $base * 4;
    .swiper-slide {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: $base * 3.875 $base * 2;

      @media (min-width: 1024px) {
        width: 65.94554865%;
        padding-top: $base * 3.75;
        padding-left: $base * 4;
      }

      a.swiper-slide__hover {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &:hover {
          background: transparent;

          .swiper-slide__gradient {
            max-width: 100%;
          }

          .swiper-slide__copy__text {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }

          .swiper-slide__button {
            opacity: 1;
            visibility: visible;
            transition: all 0.5s ease 0.1s;
            transform: translateY(0);
          }
        }
      }

      &:last-of-type {
        width: 100%;
      }

      &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        background: radial-gradient(
          100% 171.23% at 0% 100%,
          rgba($body_color_rgb, 1) 0%,
          rgba($body_color_rgb, 0.6) 50%,
          rgba($body_color_rgb, 0) 100%
        );
        width: 100%;
        max-width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        transition: max-width 0.3s ease;

        @media (min-width: 1024px) {
          max-width: 30%;
          background: radial-gradient(
            100% 171.23% at 0% 100%,
            rgba($body_color_rgb, 0.8) 0%,
            rgba($body_color_rgb, 0) 100%
          );
        }
      }

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -2;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &__copy {
        width: 100%;
        margin-bottom: $base * 3;

        @media (min-width: 1024px) {
          min-width: $base * 43;
          // width: 26.65343915vw;
        }

        &__title {
          font-size: $base * 3;
          line-height: $base * 3.75;
          text-transform: uppercase;
          color: $body_bg;
          margin: 0;
          margin-bottom: $base * 2;
          font-family: $font_family_alt;
          font-weight: normal;
          max-width: 94%;

          @media (min-width: 1024px) {
            font-size: $base * 4.5;
            line-height: $base * 5;
            max-width: 100%;
          }
        }

        &__text {
          font-size: $base * 1.75;
          line-height: auto;
          color: $body_bg;
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease;
          transform: translateY(0%);

          @media (min-width: 1024px) {
            font-size: $base * 2;
            line-height: $base * 3;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10%);
            max-width: 70%;
          }
        }
      }

      &__button {
        display: flex;
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
        transition: all 0.3s ease;

        @media (min-width: 1024px) {
          opacity: 0;
          visibility: hidden;
          transform: translateY(30%);
        }

        a {
          font-size: $base * 2;
          line-height: $base * 2.2;
          font-family: $font_family_alt;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          height: $base * 4;
          width: auto;
          color: $body_bg;
          border-radius: 500px;
          border: solid 1px $body_bg;
          padding: $base * 1 $base * 2;
          padding-bottom: $base * 0.5;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.section--educational-programs {
  .swiper-scrollbar {
    position: absolute;
    bottom: -$base * 2 !important;
    left: 0;
    width: 100%;
    // height: $base * 1.5;
    height: 4px;
    background: rgba($body_color_rgb, 0.3);
    opacity: 1;
    visibility: visible;

    // border-radius: $base * 0.75;
    @media (min-width: 1024px) {
      opacity: 0;
      visibility: hidden;
    }

    .swiper-scrollbar-drag {
      background: rgba($body_color_rgb, 1);
      // border-radius: $base * 0.75;
      opacity: 1;
    }
  }

  .swiper-slide__copy__text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font_family_alt;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}
</style>
