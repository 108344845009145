import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import { createHead } from "@vueuse/head";

const head = createHead();
const vuetify = createVuetify({
  components,
  directives,
});

//I18n
import { createI18n } from "vue-i18n";

const messages = {
  ca: require("@/i18n/ca.json"),
  es: require("@/i18n/es.json"),
  en: require("@/i18n/en.json"),
};

const i18n = createI18n({
  // something vue-i18n options here ...
  locale: "es",
  fallbackLocale: "es",
  messages,
});

createApp(App)
  .use(vuetify, { iconfont: "mdi" })
  .use(store)
  .use(i18n)
  .use(head)
  .use(router)
  .mount("#app");
