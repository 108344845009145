<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="inicio" class="page page-index">
    <div class="page-content">
      <section class="page-content--section-hero">
        <hero-slider-home :slides="heroSwiperSlides" v-if="!isLoading" />
      </section>
      <!--<pre v-if="parsedPosts">{{ parsedPosts }}</pre>-->
      <educational-programs />
      <people-section />
      <structure-section />
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import ImagesService from "@/services/images.service.js";
import { mapActions } from "vuex";

import HeroSliderHome from "@/components/sliders/HeroSliderHome.vue";
import EducationalPrograms from "@/components/sections/EducationalPrograms.vue";
import PeopleSection from "@/components/sections/PeopleSection.vue";
import StructureSection from "@/components/sections/StructureSection.vue";

export default {
  name: "IndexPage",
  components: {
    HeroSliderHome,
    EducationalPrograms,
    PeopleSection,
    StructureSection,
  },
  data() {
    return {
      posts: [],
      homePageId: 89,
      structurePageId: 541,
      heroSwiperSlides: [],
      isLoading: true,
    };
  },

  created() {
    this.fetchHomeSlides();
    document.title = "DPA UPV - " + this.$t("metaTitles.home");
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),

    getImageUrl(imagen, size) {
      return ImagesService.getImageUrl(imagen, size);
    },
    async fetchHomeSlides() {
      this.startLoading(); // Inicia el estado de carga antes de la llamada a la API

      try {
        this.heroSwiperSlides = await PostsService.getPage(
          this.homePageId
        ).then(async (res) => {
          return Promise.all(
            res.acf.slide.map(async (slide) => {
              return {
                image: slide.image.url,
                text: {
                  en: slide.text.text_en,
                  es: slide.text.text_es,
                  ca: slide.text.text_cat,
                },
                link: slide.link,
              };
            })
          );
        });
      } finally {
        this.isLoading = false;
        this.stopLoading(); // Finaliza el estado de carga después de recibir la respuesta o en caso de error
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-index {
  //   font-size: $base * 10 !important;

  //   .page-content {
  //     padding-top: $base * 9;
  //     padding-top: clamp(
  //       #{$base} * 6,
  //       calc(var(--app-height) * 0.07433808554),
  //       #{$base} * 11
  //     );
  //     padding-top: calc(var(--app-height) * 0.07433808554);
  //   }

  section.page-content--section-hero {
    // margin-bottom: $base * 15;
    height: var(--app-height);
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    padding-bottom: $base * 4;

    @media (min-width: 1024px) {
      scroll-snap-align: start;
      height: var(--app-height);
      padding-top: clamp(
        #{$base} * 6,
        calc(var(--app-height) * 0.07433808554),
        #{$base} * 11
      );
      padding-bottom: $base * 4;
    }
  }
}
</style>
