<template>
  <div class="top-bar--logo">
    <router-link to="/" :class="[dark ? 'dark' : 'light']">
      <span class="ultrabold"> DPA</span>UPV
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar--logo {
  display: flex;
  align-items: center;
  height: 100%;
  a {
    margin-top: auto;
    display: flex;
    align-items: center;
    font-size: $base * 3;
    line-height: $base * 3.4;
    font-weight: normal;
    font-family: $font_family_alt;
    mix-blend-mode: exclusion;
    color: #f6f6f6;

    &.dark {
      mix-blend-mode: exclusion;
      color: #f6f6f6;
    }
    &.light {
      mix-blend-mode: exclusion;
      color: #f6f6f6;
    }
  }
  span.ultrabold {
    font-weight: 900;
  }
}
</style>
