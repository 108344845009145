<template>
  <div class="archive-cta">
    <a
      href="https://archivedpa.webs.upv.es/"
      target="_blank"
      class="archive-cta--inner"
    >
      <div class="archive-cta--inner__main">{{ $t("archive") }}</div>
      <div class="archive-cta--inner__auxiliary">
        {{ $t("archive_cta") }}
        <span class="svg-icon">
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.78318 0.740234L5.72318 1.66023L9.62318 5.52023H0.763184V6.94023H9.64318L5.72318 10.8402L6.78318 11.7602L12.3432 6.24023L6.78318 0.740234Z"
              fill="#FF9900"
            />
          </svg>
        </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.archive-cta {
  width: auto;
  display: flex;
  justify-content: flex-end;
  .archive-cta--inner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    font-size: $base * 2.5;
    line-height: 1;
    height: $base * 4;
    text-transform: uppercase;
    font-family: $font_family_alt;
    gap: $base;
    background: $body_color;
    color: $body_bg;
    border-radius: 500px;
    padding: $base $base * 2;
    padding-top: $base * 0.965;
    transition: all 0.3s ease-out;

    &__main {
      margin-top: auto;
      //   border: solid 1px green;
      pointer-events: none;
      width: auto;
    }
    &__auxiliary {
      transition: all 0.3s ease-in-out;
      margin-top: auto;
      white-space: nowrap;
      color: $corporate_color;
      transition: all 0.3s ease-out;
      overflow: hidden;
      //   border: solid 1px red;
      flex-grow: 0;
      flex-basis: 0px;
      display: none;
      .svg-icon {
        width: $base * 2;
        height: $base * 2;
        display: inline-block;
        // border: solid 1px blue;
        svg {
          width: 100%;
          height: 100%;
          fill: $corporate_color;
        }
      }
    }
    &:hover {
      .archive-cta--inner__auxiliary {
        display: block;
        flex-grow: 1;
        flex-basis: auto;
      }
    }
  }
}
</style>
