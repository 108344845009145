<template>
  <footer class="footer">
    <div class="footer--inner">
      <div class="footer--inner--links">
        <a
          href="https://archivedpa.webs.upv.es/"
          target="_blank"
          class="footer--inner--links__item"
          >{{ $t("archive") }}</a
        >
        <a
          href="https://www.instagram.com/dpa.upv/"
          target="_blank"
          class="footer--inner--links__item"
          >Instagram</a
        >
      </div>
      <div class="footer--inner--text">
        {{ $t("footer_text") }}
      </div>
      <div class="footer--inner--logos">
        <a
          href="http://www.upv.es/entidades/ETSA/"
          target="_blank"
          rel="noopener noreferrer"
          class="footer--inner--logos__item"
        >
          <logo-etsa-svg />
        </a>
        <a
          href="https://www.upv.es"
          target="_blank"
          rel="noopener noreferrer"
          class="footer--inner--logos__item"
        >
          <logo-upv-svg />
        </a>
      </div>
      <div class="footer--inner__copyright">
        Proudly baked by
        <a href="https://contra-architecture.com/" target="_blank">CONTRA</a>
        & <a href="https://mdtec.es/" target="_blank">MDTEC</a>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoUpvSvg from "@/components/svgs/LogoUpvSvg.vue";
import LogoEtsaSvg from "@/components/svgs/LogoEtsaSvg.vue";

export default {
  components: {
    LogoUpvSvg,
    LogoEtsaSvg,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #060606;
  padding: calc(var(--app-height) * 0.06924643585) 7.407407407vw;
  padding-bottom: calc(var(--app-height) * 0.08);
  position: relative;
  &--inner {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 7.407407407vw;

    &--links {
      display: flex;
      flex-direction: column;
      width: 33%;
      a {
        color: #f6f6f6;
        font-size: $base * 6;
        line-height: $base * 6;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        transition: all 0.3s ease;
        &:hover {
          color: #060606;
          background-color: #f6f6f6;
        }
      }
    }
    &--text {
      width: 33%;
      font-size: $base * 1.375;
      line-height: $base * 2;
      text-align: center;
      color: #f6f6f6;
      margin-right: auto;
      margin-left: auto;
    }
    &--logos {
      width: 33%;
      display: flex;
      flex-wrap: nowrap;
      gap: $base * 2;
      justify-content: space-between;
      &__item {
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__copyright {
      position: absolute;
      left: 50%;
      bottom: calc(var(--app-height) * 0.02);
      transform: translateX(-50%);
      text-align: center;
      color: #f6f6f6;
      a {
        transition: all 0.3s ease;
        color: #f6f6f6;
        &:hover {
          color: $corporate_color;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.footer {
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #f6f6f6;
    }
  }
}
body.snap {
  .footer {
    scroll-snap-align: end;
  }
}
</style>
