<template>
  <div class="week-event" :class="{ today: today }">
    <div
      class="week-event--icon"
      v-if="event.typology"
      v-html="event.typology.icon"
    ></div>
    <div class="week-event--info">
      <div class="week-event--info--top">
        <h3
          class="week-event--info--top__title"
          v-if="this.$root.$i18n.locale === 'es'"
        >
          {{ event.title.es }}
        </h3>
        <h3
          class="week-event--info--top__title"
          v-if="this.$root.$i18n.locale === 'ca'"
        >
          {{ event.title.ca ? event.title.ca : event.title.es }}
        </h3>
        <h3
          class="week-event--info--top__title"
          v-if="this.$root.$i18n.locale === 'en'"
        >
          {{ event.title.en ? event.title.en : event.title.es }}
        </h3>
      </div>
      <div class="week-event--info--bottom">
        <div class="week-event--info--bottom__text">
          <div
            class="week-event--info--bottom__text__location"
            v-if="this.$root.$i18n.locale === 'es'"
          >
            {{ event.location.es }}
          </div>
          <div
            class="week-event--info--bottom__text__location"
            v-if="this.$root.$i18n.locale === 'ca'"
          >
            {{ event.location.ca ? event.location.ca : event.location.es }}
          </div>
          <div
            class="week-event--info--bottom__text__location"
            v-if="this.$root.$i18n.locale === 'en'"
          >
            {{ event.location.en ? event.location.en : event.location.es }}
          </div>
          <div class="week-event--info--bottom__text__time">
            {{ event.start_time }} -
            {{ event.end_time }}
          </div>
        </div>
        <div class="week-event--info--bottom__actions">
          <router-link
            :to="'/events/' + event.id + '/' + event.slug + '/'"
            class="week-event--info--bottom__actions__link"
          >
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="!today"
            >
              <path
                d="M15.574 25H17.8547L26 15.9854L17.8547 7H15.574L22.7715 15.1366H5V16.8634H22.7419L15.574 25Z"
                fill="#060606"
              />
              <path
                d="M16.5 33C14.6762 33 12.9382 32.7227 11.2859 32.1681C9.63929 31.6136 8.12708 30.8303 6.74922 29.8183C5.37708 28.8121 4.18503 27.6201 3.17308 26.2422C2.16684 24.8701 1.38643 23.3607 0.831861 21.7141C0.277287 20.0619 0 18.3238 0 16.5C0 14.6762 0.277287 12.941 0.831861 11.2944C1.38643 9.64215 2.16684 8.12994 3.17308 6.7578C4.18503 5.37994 5.37708 4.18789 6.74922 3.18165C8.12708 2.1697 9.63929 1.38643 11.2859 0.83186C12.9382 0.277287 14.6762 0 16.5 0C18.3238 0 20.059 0.277287 21.7056 0.83186C23.3578 1.38643 24.8701 2.1697 26.2422 3.18165C27.6201 4.18789 28.8121 5.37994 29.8183 6.7578C30.8303 8.12994 31.6136 9.64215 32.1681 11.2944C32.7227 12.941 33 14.6762 33 16.5C33 18.3238 32.7227 20.0619 32.1681 21.7141C31.6136 23.3607 30.8303 24.8701 29.8183 26.2422C28.8121 27.6201 27.6201 28.8121 26.2422 29.8183C24.8701 30.8303 23.3578 31.6136 21.7056 32.1681C20.059 32.7227 18.3238 33 16.5 33ZM16.5 31.0104C18.1065 31.0104 19.6331 30.7646 21.0795 30.2729C22.5317 29.7869 23.861 29.098 25.0673 28.2061C26.2794 27.3199 27.3285 26.2737 28.2147 25.0673C29.1065 23.8553 29.7955 22.526 30.2814 21.0795C30.7674 19.6273 31.0104 18.1008 31.0104 16.5C31.0104 14.8935 30.7674 13.3669 30.2814 11.9205C29.7955 10.4683 29.1065 9.13903 28.2147 7.93269C27.3285 6.72063 26.2794 5.67152 25.0673 4.78534C23.861 3.89345 22.5317 3.20452 21.0795 2.71855C19.6331 2.23259 18.1065 1.9896 16.5 1.9896C14.8935 1.9896 13.3641 2.23259 11.9119 2.71855C10.4654 3.20452 9.13618 3.89345 7.92412 4.78534C6.71778 5.67152 5.66866 6.72063 4.77677 7.93269C3.89059 9.13903 3.20452 10.4683 2.71856 11.9205C2.23259 13.3669 1.9896 14.8935 1.9896 16.5C1.9896 18.1008 2.23259 19.6273 2.71856 21.0795C3.20452 22.526 3.89059 23.8553 4.77677 25.0673C5.66866 26.2737 6.71778 27.3199 7.92412 28.2061C9.13618 29.098 10.4654 29.7869 11.9119 30.2729C13.3641 30.7646 14.8935 31.0104 16.5 31.0104Z"
                fill="#060606"
              />
            </svg>
            <svg
              v-else
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="33"
                height="33"
                rx="16.5"
                fill="#FF9900"
                fill-opacity="0.5"
              />
              <path
                d="M15.574 25H17.8547L26 15.9854L17.8547 7H15.574L22.7715 15.1366H5V16.8634H22.7419L15.574 25Z"
                fill="black"
              />
              <path
                d="M16.5 33C14.6762 33 12.9382 32.7227 11.2859 32.1681C9.63929 31.6136 8.12708 30.8303 6.74922 29.8183C5.37708 28.8121 4.18503 27.6201 3.17308 26.2422C2.16684 24.8701 1.38643 23.3607 0.831861 21.7141C0.277287 20.0619 0 18.3238 0 16.5C0 14.6762 0.277287 12.941 0.831861 11.2944C1.38643 9.64215 2.16684 8.12994 3.17308 6.7578C4.18503 5.37994 5.37708 4.18789 6.74922 3.18165C8.12708 2.1697 9.63929 1.38643 11.2859 0.83186C12.9382 0.277287 14.6762 0 16.5 0C18.3238 0 20.059 0.277287 21.7056 0.83186C23.3578 1.38643 24.8701 2.1697 26.2422 3.18165C27.6201 4.18789 28.8121 5.37994 29.8183 6.7578C30.8303 8.12994 31.6136 9.64215 32.1681 11.2944C32.7227 12.941 33 14.6762 33 16.5C33 18.3238 32.7227 20.0619 32.1681 21.7141C31.6136 23.3607 30.8303 24.8701 29.8183 26.2422C28.8121 27.6201 27.6201 28.8121 26.2422 29.8183C24.8701 30.8303 23.3578 31.6136 21.7056 32.1681C20.059 32.7227 18.3238 33 16.5 33ZM16.5 31.0104C18.1065 31.0104 19.6331 30.7646 21.0795 30.2729C22.5317 29.7869 23.861 29.098 25.0673 28.2061C26.2794 27.3199 27.3285 26.2737 28.2147 25.0673C29.1065 23.8553 29.7955 22.526 30.2814 21.0795C30.7674 19.6273 31.0104 18.1008 31.0104 16.5C31.0104 14.8935 30.7674 13.3669 30.2814 11.9205C29.7955 10.4683 29.1065 9.13903 28.2147 7.93269C27.3285 6.72063 26.2794 5.67152 25.0673 4.78534C23.861 3.89345 22.5317 3.20452 21.0795 2.71855C19.6331 2.23259 18.1065 1.9896 16.5 1.9896C14.8935 1.9896 13.3641 2.23259 11.9119 2.71855C10.4654 3.20452 9.13618 3.89345 7.92412 4.78534C6.71778 5.67152 5.66866 6.72063 4.77677 7.93269C3.89059 9.13903 3.20452 10.4683 2.71856 11.9205C2.23259 13.3669 1.9896 14.8935 1.9896 16.5C1.9896 18.1008 2.23259 19.6273 2.71856 21.0795C3.20452 22.526 3.89059 23.8553 4.77677 25.0673C5.66866 26.2737 6.71778 27.3199 7.92412 28.2061C9.13618 29.098 10.4654 29.7869 11.9119 30.2729C13.3641 30.7646 14.8935 31.0104 16.5 31.0104Z"
                fill="black"
              />
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {},
    },
    today: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }

.week-event {
  display: flex;
  flex-wrap: nowrap;
  border-left: solid 4px $body_color;
  padding: $base $base * 2;
  gap: $base * 1.5;
  opacity: 0.6;
  // &--icon {

  // }
  &.today {
    border-left: solid 4px $corporate_color;
    opacity: 1;
  }
  &--info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $base * 1;
    &--top {
      &__title {
        font-size: $base * 2;
        line-height: $base * 2.5;
        text-transform: uppercase;
        font-weight: 400;
        color: $body_color;
      }
    }
    &--bottom {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      &__text {
      }
      &__actions {
        display: flex;
        flex-wrap: nowrap;
        &__link {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
