<template>
  <div class="related-posts--swiper">
    <swiper
      :slidesPerView="1"
      :space-between="16"
      grabCursor
      :loop="true"
      :navigation="true"
      :modules="modules"
      :breakpoints="{
        '768': {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      }"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <a :href="slide.project_url" target="_blank" class="swiper-slide--link">
          <!-- Render original HTML in server, render Swiper in browser (client) -->
          <div class="swiper-slide--link__img">
            <img :src="slide.project_image.sizes.large" />
          </div>
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation],
    };
  },
};
</script>
<style lang="scss" scoped>
.related-posts--swiper {
  width: 100%;

  .swiper-slide {
    position: relative;
    height: calc(var(--app-height) * 0.2281059063);
    width: 100%;
    &--link {
      display: block;
      height: 100%;
      width: 100%;
      .swiper-slide--link__img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.related-posts--swiper {
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    overflow: hidden;
  }
  .swiper-button-prev {
    left: -$base * 3.5;
    @media (min-width: 1024px) {
      left: -$base * 4.5;
    }
    &:after {
      content: url(../../assets/svgs/icon-arrow-left.svg);
    }
  }
  .swiper-button-next {
    right: -$base * 3.5;
    @media (min-width: 1024px) {
      right: -$base * 4.5;
    }
    &:after {
      content: url(../../assets/svgs/icon-arrow-right.svg);
    }
  }
}
</style>
