<template>
  <swiper
    slides-per-view="auto"
    :space-between="32"
    :scrollbar="{ draggable: true }"
    :modules="modules"
    grabCursor
  >
    <swiper-slide v-for="n in 3" :key="n">
      <div class="skeleton-loader"></div
    ></swiper-slide>
  </swiper>
</template>
<script>
// import Swiper core and required modules
import { Scrollbar } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {},
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   // console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Scrollbar],
    };
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  overflow: visible;
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    // gap: $base * 4;
    .swiper-slide {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: $base * 3.875 $base * 2;
      @media (min-width: 1024px) {
        width: 65.94554865%;
        padding-top: $base * 3.75;
        padding-left: $base * 4;
      }
      .skeleton-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba($body_color_rgb, 0.7),
          rgba($body_color_rgb, 0.9),
          rgba($body_color_rgb, 0.7)
        );
        background-size: 200%;
        animation: loader 1s infinite reverse;
        z-index: 1;
      }
      @keyframes loader {
        from {
          background-position: -100% 0;
        }
        to {
          background-position: 100% 0;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.section--educational-programs {
  .swiper-scrollbar {
    position: absolute;
    bottom: -$base * 2 !important;
    left: 0;
    width: 100%;
    // height: $base * 1.5;
    height: 4px;
    background: rgba($body_color_rgb, 0.3);
    opacity: 1;
    visibility: visible;

    // border-radius: $base * 0.75;
    @media (min-width: 1024px) {
      opacity: 0;
      visibility: hidden;
    }

    .swiper-scrollbar-drag {
      background: rgba($body_color_rgb, 1);
      // border-radius: $base * 0.75;
      opacity: 1;
    }
  }
}
</style>
