<template>
  <router-link
    class="month-event"
    :to="'/events/' + event.id + '/' + event.slug + '/'"
  >
    <div
      class="month-event--icon"
      v-if="event.typology"
      v-html="event.typology.icon"
    ></div>
    <span class="month-event--title" v-if="this.$root.$i18n.locale === 'es'">{{
      event.title.es
    }}</span>
    <span class="month-event--title" v-if="this.$root.$i18n.locale === 'ca'">{{
      event.title.ca ? event.title.ca : event.title.es
    }}</span>
    <span class="month-event--title" v-if="this.$root.$i18n.locale === 'en'">{{
      event.title.en ? event.title.en : event.title.es
    }}</span>
    <div class="month-event--image">
      <img :src="event.image" />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }
.month-event {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: $base;
  position: relative;
  width: 100%;
  &:hover {
    .month-event--image {
      opacity: 1;
      visibility: visible;
      right: 102%;
    }
  }
  &--icon {
    width: 100%;
    height: 100%;
    max-width: $base * 3;
    max-height: $base * 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--title {
    font-size: $base * 2.5;
    line-height: $base * 3;
    font-family: $font_family_alt;
    text-align: left;
    width: 100%;
  }
  &--image {
    position: absolute;
    bottom: 50%;
    right: 80%;
    opacity: 0;
    visibility: hidden;
    transition: all ease-out 0.3s;
    width: 100%;
    height: 100%;
    min-width: 10vw;
    min-height: 10vw;
    max-width: 10vw;
    max-height: 10vw;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
