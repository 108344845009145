<template>
  <section class="structure-section" v-if="structurePageContent">
    <breadcrumbs-component :hideBreadcrumbs="true"></breadcrumbs-component>
    <div class="structure-section--header">
      <h2
        v-if="
          this.$root.$i18n.locale === 'es' && this.structurePageContent.title
        "
      >
        {{ structurePageContent.title.es }}
      </h2>
      <h2
        v-if="
          this.$root.$i18n.locale === 'ca' && this.structurePageContent.title
        "
      >
        {{
          structurePageContent.title.ca
            ? structurePageContent.title.ca
            : structurePageContent.title.es
        }}
      </h2>
      <h2
        v-if="
          this.$root.$i18n.locale === 'en' && this.structurePageContent.title
        "
      >
        {{
          structurePageContent.title.en
            ? structurePageContent.title.en
            : structurePageContent.title.es
        }}
      </h2>
      <a :href="this.structurePageContent.link" target="__blank"
        >{{ $t("structure_link_text") }} →</a
      >
    </div>
    <!-- <pre style="background-color: white">{{ structurePageContent }}</pre> -->
    <div class="structure-section--content">
      <div class="structure-section--content__left">
        <div
          class="structure-section--content__left__text"
          v-if="
            this.$root.$i18n.locale === 'es' &&
            this.structurePageContent.content
          "
          v-html="this.structurePageContent.content.es"
        ></div>
        <div
          class="structure-section--content__left__text"
          v-if="
            this.$root.$i18n.locale === 'ca' &&
            this.structurePageContent.content
          "
          v-html="
            this.structurePageContent.content.ca
              ? this.structurePageContent.content.ca
              : this.structurePageContent.content.es
          "
        ></div>
        <div
          class="structure-section--content__left__text"
          v-html="
            this.structurePageContent.content.en
              ? this.structurePageContent.content.en
              : this.structurePageContent.content.es
          "
          v-if="
            this.$root.$i18n.locale === 'en' &&
            this.structurePageContent.content
          "
        ></div>
      </div>
      <div
        v-if="!this.$vuetify.display.mobile"
        class="structure-section--content__right"
      >
        <div
          class="structure-section--content__right__img"
          v-if="structurePageContent.image"
        >
          <img :src="structurePageContent.image.large" alt="" />
        </div>
      </div>
    </div>
    <div class="structure-section--contact">
      <h3 class="structure-section--contact__title">
        {{ $t("contact_data") }}
      </h3>
      <div class="structure-section--contact--grid">
        <article
          class="structure-section--contact--grid__item"
          v-for="contact in structurePageContent.contacts"
          :key="contact.id"
        >
          <h4 class="structure-section--contact--grid__item__name">
            <span>→</span>{{ contact.name }}
          </h4>
          <div
            class="structure-section--contact--grid__item__position"
            v-if="this.$root.$i18n.locale === 'es'"
          >
            {{ contact.position.position_es }}
          </div>
          <div
            class="structure-section--contact--grid__item__position"
            v-if="this.$root.$i18n.locale === 'ca'"
          >
            {{
              contact.position.position_cat
                ? contact.position.position_cat
                : contact.position.position_es
            }}
          </div>
          <div
            class="structure-section--contact--grid__item__position"
            v-if="this.$root.$i18n.locale === 'en'"
          >
            {{
              contact.position.position_en
                ? contact.position.position_en
                : contact.position.position_es
            }}
          </div>
          <a
            class="structure-section--contact--grid__item__phone"
            :href="'tel:' + contact.phone"
            target="__blank"
            v-show="contact.phone"
            >{{ contact.phone }}</a
          >
          <a
            class="structure-section--contact--grid__item__mail"
            :href="'mailto:' + contact.email"
            target="__blank"
            v-show="contact.email"
            >{{ contact.email }}</a
          >
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import PostsService from "@/services/posts.service.js";
import BreadcrumbsComponent from "@/components/layout/BreadcrumbsComponent.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      structurePageId: 541,
      structurePageContent: [],
      loading: false,
    };
  },
  components: {
    BreadcrumbsComponent,
  },
  created() {
    this.startLoading(); // Inicia el estado de carga antes de la llamada a la API

    this.fetchStructurePage();
    document.title = "DPA UPV - " + this.$t("metaTitles.structure");
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    async fetchStructurePage() {
      this.loading = true;
      this.startLoading(); // Inicia el estado de carga antes de la llamada a la API
      try {
        const result = await PostsService.getPage(this.structurePageId).then(
          async (res) => {
            const structurePageContent = {
              title: {
                es: res.acf.name.name_es,
                en: res.acf.name.name_en,
                ca: res.acf.name.name_cat,
              },
              content: {
                es: res.acf.content.content_es,
                en: res.acf.content.content_en,
                ca: res.acf.content.content_cat,
              },
              image: {
                full: res.acf.image.url ? res.acf.image.url : null,
                large: res.acf.image.sizes.large
                  ? res.acf.image.sizes.large
                  : null,
              },
              link: res.acf.external_link,
              slug: res.slug,
              id: res.id,
              contacts: res.acf.contact_list,
              // otros campos
            };

            return structurePageContent;
          }
        );
        this.stopLoading(); // Finaliza el estado de carga después de un retraso de 1 segundo
        this.loading = false;

        this.structurePageContent = result;

        return this.structurePageContent; // Devuelve la propiedad
      } catch (error) {
        // Maneja el error de la llamada a la API
        console.error(error);
        // this.stopLoading(); // Finaliza el estado de carga en caso de error
        this.loading = false;

        return null; // Retorna null en caso de error
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }

section.structure-section {
  padding: 0 $base * 4;
  padding-top: $base * 9;
  padding-top: clamp(
    #{$base} * 6,
    calc(var(--app-height) * 0.06433808554),
    #{$base} * 11
  );
  padding-bottom: $base * 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $base * 10;

  @media (min-width: 1024px) {
    padding: 0 $base * 2;
    padding-top: clamp(
      #{$base} * 6,
      calc(var(--app-height) * 0.07433808554),
      #{$base} * 11
    );
    display: grid;

    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    column-gap: $base * 3;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .structure-section--header {
    // padding-top: calc(var(--app-height) * 0.06433808554);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $base * 4;

    @media (min-width: 1024px) {
      font-size: $base * 8.5;
      line-height: $base * 9;
      // flex: 1 1 auto;
      margin-bottom: 0;
      grid-column: 2 / span 10;
    }

    h2 {
      font-size: $base * 4;
      line-height: $base * 5;
      color: $body_color;
      font-family: $font_family_alt;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: $base * 3.75;

      @media (min-width: 1024px) {
        font-size: $base * 8.5;
        line-height: $base * 9;
        margin-bottom: 0;
      }
    }

    a {
      font-family: $font_family_alt;
      transition: color 0.2s ease;
      color: rgba($body_color_rgb, 0.7);
      font-size: $base * 2;
      line-height: $base * 3;
      margin-bottom: $base * 4;

      &:hover {
        color: $corporate_color;
      }
    }
  }

  .structure-section--content {
    display: flex;
    flex-wrap: nowrap;
    gap: 4.62962963vw;
    margin-bottom: $base * 2.5;
    grid-column: 2 / span 10;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-auto-rows: max-content;
      margin-bottom: $base * 10;
    }

    &__left {
      color: $body_color;
      width: 100%;
      gap: $base * 2;
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        column-gap: $base * 3;
        grid-column: 1 / span 7;
      }

      &__text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: $font_family_alt;
          font-weight: normal;
          text-transform: uppercase;
          margin-bottom: $base;
        }

        p {
          font-family: $font_family_base;
        }
      }

      &__button {
        flex: 0.2 1 auto;

        a {
          width: fit-content;
          display: block;
          font-size: $base * 2;
          line-height: $base * 2;
          color: $body_color;
          font-family: $font_family_alt;
          padding: $base * 1.2 $base * 2;
          padding-bottom: $base * 0.8;
          text-transform: uppercase;
          font-weight: normal;
          text-decoration: none;
          border: 1px solid $body_color;
          border-radius: 500px;

          @media (min-width: 1024px) {
            font-size: $base * 3;
            line-height: $base * 3;
          }
        }
      }
    }

    &__right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $base * 2;

      @media (min-width: 1024px) {
        column-gap: $base * 3;
        grid-column: 8 / span 3;
      }

      &__img {
        width: 100%;
        height: 100%;
        max-height: calc(var(--app-height) * 0.9460285132);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .structure-section--contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: $base * 2.5;

    @media (min-width: 1024px) {
      grid-column: 2 / span 10;
      margin-bottom: $base * 10;
    }

    &__title {
      font-size: $base * 2;
      line-height: $base * 3;
      font-family: $font_family_alt;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: $base * 3.5;
      margin-left: -$base * 2;
      @media (min-width: 1024px) {
        font-size: $base * 3;
        line-height: $base * 4;
        margin-left: 0;
        margin-bottom: $base * 5;
      }
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: max-content;
      gap: $base * 2;
      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
        gap: $base * 5;
      }
      &__item {
        display: flex;
        flex-direction: column;
        gap: $base;
        &__name {
          font-size: $base * 1.75;
          line-height: $base * 2;
          font-weight: 400;
          @media (min-width: 1024px) {
            font-size: $base * 2.75;
            line-height: $base * 4;
            margin-bottom: 0;
            span {
              margin-left: -$base * 3;
              margin-right: $base * 0.5;
            }
          }
        }
        &__position {
          font-size: $base * 1.75;
          line-height: $base * 2;
          font-family: $font_family_base;
          font-weight: normal;
          color: #670069;
          @media (min-width: 1024px) {
            font-size: $base * 2.75;
            line-height: $base * 3;
          }
        }
        &__phone {
          font-size: $base * 1.75;
          line-height: $base * 2;
          font-family: $font_family_base;
          font-weight: normal;
          @media (min-width: 1024px) {
            font-size: $base * 2.75;
            line-height: $base * 3;
          }
        }
        &__mail {
          font-size: $base * 1.75;
          line-height: $base * 2;
          font-family: $font_family_base;
          font-weight: normal;
          @media (min-width: 1024px) {
            font-size: $base * 2.75;
            line-height: $base * 3;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.structure-section--content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font_family_alt;
    font-weight: 400;
    text-transform: uppercase;
  }
}
</style>
