/* eslint-disable class-methods-use-this */
import axios from "axios";

// const API_URL = `${process.env.VUE_APP_API_URL}`
// const API_URL = `https://wptemplate.dev.mdtec.es/`;
const API_URL = `https://admin.dparq-etsav.webs.upv.es/`;

class PostsService {
  getPage(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/pages/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  getPosts() {
    return axios
      .get(`${API_URL}wp-json/wp/v2/posts?acf_format=standard`)
      .then((response) => response.data);
  }
  getSinglePost(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/posts/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  getEvents() {
    return axios
      .get(`${API_URL}wp-json/wp/v2/events?acf_format=standard`)
      .then((response) => response.data);
  }
  getSingleEvent(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/events/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  //get related events, has to share the same custom taxonomy id (typology)
  getRelatedEvents(id, perPage) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/events?acf_format=standard&typology=${id}&per_page=${perPage}`
      )
      .then((response) => response.data);
  }
  getNews() {
    return axios
      .get(`${API_URL}wp-json/wp/v2/news?acf_format=standard`)
      .then((response) => response.data);
  }
  getSingleNews(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/news/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  getEventsByDateRange(startDate, endDate) {
    return axios
      .get(
        `${API_URL}/wp-json/my/v1/events?start_date=${startDate}&end_date=${endDate}`
      )
      .then((response) => response.data);
  }
  getPaginationNews(page, perPage, order, orderby) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/news?acf_format=standard&page=${page}&per_page=${perPage}&order=${order}&orderby=${orderby}`
      )
      .then((response) => response.data);
  }
  getPaginationPeople(page, perPage, order, orderby, taxonomyId) {
    const taxonomyFilter = taxonomyId ? `&roles=${taxonomyId}` : "";

    return axios
      .get(
        `${API_URL}wp-json/wp/v2/people?acf_format=standard&page=${page}&per_page=${perPage}&order=${order}&orderby=${orderby}${taxonomyFilter}`
      )
      .then((response) => response);
  }

  getPaginationPeopleByCustomTaxonomy(
    page,
    perPage,
    order,
    orderby,
    taxonomy,
    id
  ) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/people?acf_format=standard&page=${page}&per_page=${perPage}&order=${order}&orderby=${orderby}&${taxonomy}=${id}`
      )
      .then((response) => response);
  }
  getSinglePeople(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/people/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  getRandomPeople(perPage) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/people?per_page=${perPage}&orderby=rand&acf_format=standard`
      )
      .then((response) => response.data);
  }
  getSinglePeopleBySlug(slug) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/people?slug=${slug}`)
      .then((response) => response.data);
  }
  getPaginationPrograms(page, perPage, order, orderby) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/programs?acf_format=standard&page=${page}&per_page=${perPage}&order=${order}&orderby=${orderby}`
      )
      .then((response) => response.data);
  }
  getSingleProgram(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/programs/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  getProgramsByTaxonomy(taxonomy, id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/programs?${taxonomy}=${id}`)
      .then((response) => response.data);
  }
  getAllCustomTaxonomies(taxonomy) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/${taxonomy}?acf_format=standard`)
      .then((response) => response.data);
  }
  getTaxonomyFields(taxonomy, id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/${taxonomy}/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  // Exporta la función para usarla en otros archivos
  getUnitById(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/units/${id}?acf_format=standard`)
      .then((response) => response.data);
  }
  getPaginationUnits(page, perPage, order, orderby) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/units?acf_format=standard&page=${page}&per_page=${perPage}&order=${order}&orderby=${orderby}`
      )
      .then((response) => response.data);
  }
  getSingleImage(id) {
    return axios
      .get(`${API_URL}wp-json/wp/v2/media/${id}`)
      .then((response) => response.data);
  }
  getImage(id, size) {
    return axios
      .get(
        `${API_URL}wp-json/wp/v2/media/${id}?acf_format=standard&size=${size}`
      )
      .then((response) => response.data);
  }
}

export default new PostsService();
